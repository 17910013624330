import React, { useState, useRef } from 'react';
import { Link, useParams, useSearchParams } from "react-router-dom";
import { Tabs } from "antd";

import Album from './Album';
import "./Library.css";

import { useTokensWOMetadata } from "../hooks/useTokensWOMetadata";


import { LoginOutlined, LogoutOutlined } from "@ant-design/icons";



import { useMoralisWeb3Api, useMoralis } from "react-moralis";

import * as localForage from 'localforage';


const BenApeTape = "https://arweave.net/fywJXh2FHI6rusySW5bSc0EcQNT78m0JvBKluY3Nyxg";


const { TabPane } = Tabs;




const Library = ({ setNftAlbum, setSongChoice, isSearch, setBottomText }) => {

  const Web3Api = useMoralisWeb3Api();
  const { authenticate, isAuthenticated, isAuthenticating, logout } = useMoralis();

  const [deleteLocalDone, setDeleteLocalDone] = useState();


  //cool stuff
  let { wallet } = useParams();
console.log(wallet, "library wallet from params")
let [searchParams, setSearchParams] = useSearchParams();
console.log(searchParams.get("wallet"), "wallet from library search params")
//

  var deleteLocalButtonCode;
  const logoutFunction = () => {
    setBottomText("");
    logout();
  }


  const deleteLocal = async () => {
    deleteLocalButtonCode = "";
    destroyStorage();
    return setDeleteLocalDone(true);
  }
  const destroyStorage = async () => {
    localForage.clear();
  }
  if (deleteLocalDone != true) {
    deleteLocalButtonCode = <button className="deleteLocalFiles" onClick={() => deleteLocal()}>Delete All Local Files</button>
  }


  // const textField1 = useRef([]);
  const textField1 = useRef();
  const textField2 = useRef();


  if (!isAuthenticated) {
    return (
      <>
        <Tabs defaultActiveKey="2" centered>
          <TabPane tab="Your Wallet" key="1">
            <div className="albums">
              <container >
                {deleteLocalButtonCode}
                <button onClick={() => authenticate({ signingMessage: "Log in to ApeTapes" })} className="logIn" >
                  <LoginOutlined /> Log In
                </button>
              </container>
            </div>
            <img src={BenApeTape} alt="Logo" className="logo2" style={{ marginLeft: "40%" }}></img>
          </TabPane>
          <TabPane tab="Settings" key="2">
            {/* <h1 className="featuredTitle">Wallet Page</h1> */}
            <div className="albums">
              <container >
                {deleteLocalButtonCode}
                <button onClick={() => authenticate({ signingMessage: "Log in to ApeTapes" })} className="logIn" >
                  <LoginOutlined /> Log In
                </button>
              </container>
            </div>
            <img src={BenApeTape} alt="Logo" className="logo2" style={{ marginLeft: "40%" }}></img>
          </TabPane>
        </Tabs>
        <div className="container" >


        </div>
      </>
    )
  }



  const reSyncMetadata = async (contract, id) => {

    const options = {
      // chain: "rinkeby",
      chain: "eth",
      address: contract.toString(),
      token_id: id.toString(),
      flag: "metadata",
    };
    const result = await Web3Api.token.reSyncMetadata(options);
    console.log(result, "resync metadata", contract, id);
    return result

  };



  // const { tokensWOMetadata } = useTokensWOMetadata();




  return (

    <>
      <Tabs defaultActiveKey="1" centered>
        <TabPane tab="Your Wallet" key="1">
          <Album setNftAlbum={setNftAlbum} setSongChoice={setSongChoice} isSearch={isSearch} />
        </TabPane>
        <TabPane tab="Settings" key="2">
          {/* <h1 className="featuredTitle">Settings</h1> */}
          <div className="albums">
            <container>
              {deleteLocalButtonCode}
              <button onClick={() => logoutFunction()} className="logOut">
                <LogoutOutlined />  Log Out
              </button>

              <h3 className='text' >Resync Metadata</h3>

              <p className='text'>Contract:
                <input
                  id="copyInput"
                  type="text"
                  placeholder="Address"
                  // value={copyText.current}
                  style={{ width: "min(35%,300px)", marginRight: "2%", color: "black" }}
                  onChange={e => { console.log(e.target.value, "field 1"); return textField1.current = e.target.value }}
                  onKeyPress={(ev) => {
                    if (ev.key === "Enter") {
                      ev.preventDefault();
                      console.log(ev.target.value);

                    }
                  }} />

                <button style={{ color: "black" }} onClick={() => { reSyncMetadata(textField1.current, textField2.current) }}>
                  Send Request
                </button>
              </p>


              <p className='text'>Token Id:
                <input
                  id="copyInput"
                  type="text"
                  placeholder="#"
                  // value={copyText.current}
                  style={{ width: "min(35%,300px)", color: "black" }}
                  onChange={e => { console.log(e.target.value, "field 2"); return textField2.current = e.target.value }}
                  onKeyPress={(ev) => {
                    if (ev.key === "Enter") {
                      ev.preventDefault();
                      console.log(ev.target.value);

                    }
                  }} />
              </p>


            </container>

    

          </div>
          <div>
            <img src={BenApeTape} alt="Logo" className="logo2" style={{ marginLeft: "40%" }}></img>
          </div>

        </TabPane>

      </Tabs>

    </>


  )



}


export default Library;
