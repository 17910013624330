import { useEffect, useState } from "react"
import { useMoralis } from "react-moralis"

import * as localForage from 'localforage';
import { soundXYZAbi } from "../helpers/soundXYZAbi";


export const useArtistContracts = () => {


    const [artistContracts, setArtistContracts] = useState();
    const { Moralis } = useMoralis();


    async function artistContractsFunction(input) {

        var inputText = "<input>"

        const options = {
            chain: "eth",
            address: "0x78E3aDc0E811e4f93BD9F1f9389b923c9A3355c2",
            function_name: "artistContracts",
            abi: soundXYZAbi,
            params: { '': input.toString() },
        };

        const results = await Moralis.Web3API.native.runContractFunction(options);
        // console.log(results, "results")
        return results
        // return await Moralis.Web3API.native.runContractFunction(options);
    }

    const getArtistContracts = async () => {

        const value = await localForage.getItem("soundXYZContracts");

            if (value && value.length > 0) {
                console.log("soundxyz contracts", value.length)
                setArtistContracts(value)
                return value
            }
        

        var loopdata = [];
        var listLength = localStorage.getItem("soundXYZCount");
        console.log(localStorage.getItem("soundXYZCount"), "localstorage")


        const Results = Moralis.Object.extend("soundXYZ");
        const query = new Moralis.Query(Results);
        const queryResults = await query.find();

        if (queryResults.length == 1) {
            var contracts = queryResults[0].attributes.contracts
            console.log(queryResults[0].attributes, "queryResults")
            // console.log(queryResults[0].attributes.contracts, "queryResults")
            if (contracts && contracts.length > 0) {
                localForage.setItem("soundXYZContracts", contracts).then((value) => {
                    console.log("localForage set in use artist contracts", value);
                })

                setArtistContracts(contracts)
                return contracts;
            }
        }


        // const contracts = await query.get("contracts");
        for (let i = 0; i <= queryResults.length; i++) {
            console.log(queryResults, "queryResults")
            localForage.removeItem('soundXYZContracts')
            if (queryResults && i < queryResults.length) {
                listLength = queryResults[i].attributes.listLength
                queryResults[i].destroy().then(
                    (myObject) => {
                        // The object was deleted from the Moralis Cloud.
                        console.log(myObject, "object was destroyed")
                    },
                    (error) => {
                        // The delete failed.
                    }
                )
            }
        }
        // console.log(contracts, "contracts")


        async function loop(count) {

            var results
            results = await artistContractsFunction(count);


            setTimeout(async () => {

                if (results && results.length == 42) {
                    loopdata = loopdata.concat(results)
                    count++
                    // console.log(loopdata, count, "loopdata results")
                    console.log(count, "loopdata results")
                    if (count != listLength) {
                        loop(count)
                    } else if (count == listLength) {
                        console.log("the end was reached")


                        var soundXYZ
                        soundXYZ = new Results();
                        soundXYZ.set("listLength", listLength)
                        soundXYZ.set("contracts", loopdata)
                        soundXYZ.save().then(
                            (result) => {
                                console.log("saved soundxyz moralis object")
                            },
                            (error) => {

                            }
                        );

                        localForage.setItem("soundXYZContracts", loopdata).then((value) => {
                            console.log("localForage set in home", value);
                        })

                        setArtistContracts(loopdata)
                        return loopdata;
                    }
                } else {
                    setArtistContracts(loopdata)
                    return loopdata;
                }
                

            }, 150);
            
        }

        return loop(0);

    }

    useEffect(() => {

        console.log("got to useeffect")
        getArtistContracts();

    }, []);

    return { getArtistContracts, artistContracts };
}
