import { useEffect, useState, useRef } from "react"

import { useMoralisWeb3Api, useMoralis } from "react-moralis";

import { blacklist } from "../helpers/blacklist";
import * as localForage from 'localforage';



export const useContractFilter = (tokenList, address, selected) => {

    const Web3Api = useMoralisWeb3Api();
    const { Moralis } = useMoralis();

    const ignoreCase = require('ignore-case');

    const [filterContracts, setFilterContracts] = useState();

    var elementData = useRef([]);



    const contractFilter = async () => {

        var tightSearch = true;
        var selectedTokens

        console.log(selected, "selected variable inside useContractfilter")
        console.log(tokenList, "tokenList variable inside useContractfilter")

        let tokens = tokenList;



        const reSyncMetadata = async (contract, id) => {

            const options = {
                // chain: "rinkeby",
                chain: "eth",
                address: contract,
                token_id: id,
                flag: "metadata",
            };
            const result = await Web3Api.token.reSyncMetadata(options);
            console.log(result, "resync metadata");
            return result

        };


        //
        //Begin destroy storage functions

        const refreshServer = async () => {
            destroyPlaylist();
            destroyPlaylist2();
            destroyStorage();
            return;
        }


        const destroyPlaylist = async () => {

            const Results = Moralis.Object.extend("ResultData");
            const query = new Moralis.Query(Results);
            query.equalTo("contract", address.toUpperCase());
            const queryResults = await query.find();
            for (let i = 0; i <= queryResults.length; i++) {
                queryResults[i].destroy().then(
                    (myObject) => {
                        // The object was deleted from the Moralis Cloud.
                        // alert("Successful deletion of result data");
                    },
                    (error) => {
                        // The delete failed.
                        // error is a Moralis.Error with an error code and message.
                    }
                )
            }
            return;
        }

        const destroyPlaylist2 = async () => {

            const Results = Moralis.Object.extend("FilterData");
            const query = new Moralis.Query(Results);
            query.equalTo("contract", address.toUpperCase());
            query.doesNotExist("selected");
            query.doesNotExist("traitSelected");

            const queryResults = await query.find();
            for (let i = 0; i < queryResults.length; i++) {
                queryResults[i].destroy().then(
                    (myObject) => {
                        // The object was deleted from the Moralis Cloud.
                        // alert("Successful deletion of filter data");
                    },
                    (error) => {
                        // The delete failed.
                        // error is a Moralis.Error with an error code and message.
                    }
                )
            }

            return;
        }

        const destroyStorage = async () => {
            localForage.removeItem(address.toUpperCase())
            localForage.removeItem(address.toUpperCase() + "Time")
            localForage.removeItem(address + "[][]")
            localForage.removeItem(address + "[][]Time")
        }
        //End destroy storage functions
        //


        if (!tokenList) { } else {


            //Dreamloops Test lol
            selectedTokens = tokens.filter(element => {
                //console.log(!JSON.parse(element.metadata), "element")

                if (!element) { return }
                if (!element.metadata) {
                    if (selected) {
                        console.log(element, "element")
                        console.log(selected, "selected ")
                        for (let i = 0; i < selected.length; i++) {

                            if (ignoreCase.includes(element.token_address, selected[i])) {
                                console.log(selected[i], "selected i")
                                console.log(selected[i], "selected to resync", element.token_address, element.token_id)

                                // await reSyncMetadata(element.token_address, element.token_id);

                                // reSyncMetadata(element.token_address, element.token_id);
                                elementData.current = elementData.current.concat(element);
                                console.log("it matched!", element.token_address, "and", selected[i])

                            }

                        }
                        
                        
                    }
                    
                    console.log("here2")
                    return
                }
                if (!JSON.parse(element.metadata).animation_url) { return }
                if (!JSON.parse(element.metadata).attributes) {
                    // console.log(JSON.parse(element.metadata), "element metadata");
                    let newElement = element
                    let newItem = JSON.parse(element.metadata)
                    newItem.attributes = []
                    newElement.metadata = JSON.stringify(newItem)
                    return newElement
                }
                return element
                
            });
            

            console.log(elementData.current, "elementData")
            if (elementData.current && elementData.current.length == 0) {
                localForage.removeItem("tokensWithoutMetadata").then(function (value) {
                    console.log("localForage removed in useContractFilter for tokensWithoutMetadata", value);
                })
            }


            if (elementData && elementData.current.length != 0) {
                // setTimeout(() => {
                // for (let i = 0; i < elementData.current.length; i++) {
                //     reSyncMetadata(elementData.current[i].token_address, elementData.current[i].token_id);
                // }
                reSyncMetadata(elementData.current[0].token_address, elementData.current[0].token_id);
                // }, 500);
                console.log(elementData.current, "found element data. refreshing server data for wallet address")

                localForage.setItem("tokensWithoutMetadata", elementData.current).then(function (value) {
                    console.log("localForage set in useContractFilter for tokensWithoutMetadata", value);
                })

                refreshServer();
            }





            console.log(selectedTokens, "selectedTokens after filtering away the ones that don't have audio")
            tokens = await selectedTokens;
            selectedTokens = [];
            console.log((selected && selected.length), "selected length")


            if (!selected || selected.length == 0) {
                selectedTokens = tokens;
            } else {
                for (let i = 0; i < selected.length; i++) {

                    console.log(i, "i testing")
                    var contractTokens
                    contractTokens = tokens && tokens.filter(checkSelection); function checkSelection(token) {
                        var ans;
                        // console.log(token.token_address, "token")
                        // console.log(selected[i], "selected i ")
                        if (ignoreCase.includes(token.token_address, selected[i])) { ans = true; }

                        console.log(ans, "ans")
                        if (ans != true) { token = "" }
                        { return token; }
                    }

                    selectedTokens = selectedTokens.concat(contractTokens);
                    console.log(selectedTokens, "selectedtokens")
                }
            }


            //blacklist attempt

            tokens = selectedTokens;
            selectedTokens = [];
            console.log(blacklist.length, "blacklist length")

            for (let i = 0; i < blacklist.length; i++) {

                console.log(i, "i blacklist testing")
                //         var contractTokens
                // contractTokens = tokens.filter(checkSelection); function checkSelection(token) {
                //     var ans;
                //     if (ignoreCase.includes(token.token_address, blacklist[i])) { ans = true; }
                //     if (ans == true) { token = "" }
                //     { return token; }
                // }
                // selectedTokens = selectedTokens.concat(contractTokens);

                tokens = tokens.filter(checkSelection); function checkSelection(token) {
                    var ans;
                    if (ignoreCase.includes(token && token.token_address, blacklist[i].contract)) { ans = true; }
                    if (ans == true) { token = "" }
                    { return token; }
                }
                selectedTokens = tokens;
            }



        }




        // console.log(selectedTokens, "All sorted")

        await setFilterContracts(selectedTokens)

        return await selectedTokens
        //return await selectedTokens.then((result) => result);

    };

    useEffect(() => {
        contractFilter();
        //return console.log("yo bruv useEffect innit")
    }, [tokenList]);


    return { contractFilter, filterContracts }
}
