import React from 'react'
import "./AudioPlayer.css";
import {Slider} from "antd";
import useAudio from "../hooks/useAudio";
import { SoundOutlined, StepBackwardOutlined, StepForwardOutlined, PlayCircleFilled, PauseCircleFilled, LoadingOutlined } from "@ant-design/icons";
import { useIPFS} from "../hooks/useIPFS";




function AudioPlayer({nftAlbum, songChoice}) {

  const {resolveLink} = useIPFS();
  const [
    playing,
    duration,
    toggle,
    toNextTrack,
    toPrevTrack,
    trackProgress,
    onSearch,
    onSearchEnd,
    onVolume,
    trackIndex
  ] = useAudio(nftAlbum, songChoice);

  //console.log(trackIndex,"trackIndex");

  const minSec = (secs) => {
    const minutes = Math.floor(secs / 60);
    const returnMin = minutes < 10 ? `0${minutes}` : minutes;
    const seconds = Math.floor(secs % 60);
    const returnSec = seconds <10 ? `0${seconds}` : seconds;

    return `${returnMin}:${returnSec}`;
  };

  var coverimg
  if(nftAlbum[trackIndex]){coverimg = resolveLink(JSON.parse(nftAlbum[trackIndex].metadata).image)}

  var songTitle
  if (nftAlbum[trackIndex]){songTitle = JSON.parse(nftAlbum[trackIndex].metadata).name.substr(0, 30)}

  var footerTokenId
  if (nftAlbum[trackIndex]){footerTokenId = JSON.parse(nftAlbum[trackIndex].token_id.substr(0,5))}

  
  return(

    <>
      <div className="buttons" style={{width:"30%", justifyContent:"start"}}>
        <img className="cover" src={coverimg} alt="currentCover" />
        <div>
          <div className="songTitle" >{songTitle}</div>
          {/*<div className="songAlbum">{JSON.parse(nftAlbum[trackIndex].metadata).attributes[4].value}</div>*/}
          {/* <div className="footNumberHeader"> Token id {footerTokenId}</div> */}
        </div> 
      </div>
      <div className="middlePart"style={{marginLeft:"-10%", justifyContent:"right"}}>
        <div className="buttons" >
          <StepBackwardOutlined className="forback" onClick={toPrevTrack} />
          {playing ?
            <PauseCircleFilled className="pauseplay" onClick={toggle} /> :
            <PlayCircleFilled className="pauseplay" onClick={toggle} />
          }
          <StepForwardOutlined className="forback" onClick={toNextTrack} />
        </div>
        <div className="buttons">
          {minSec(trackProgress)}
          <Slider
            value={trackProgress}
            step={1}
            min={0}
            max={duration ? duration : 0}
            className="progress"
            tooltipVisible={false}
            onChange={(value) => onSearch(value)}
            onAfterChange={onSearchEnd}
          />
          {duration ? minSec(Math.round(duration)) : "00:00" }
          {duration ? "" : <LoadingOutlined/> }
        </div>
      </div>
      <div className="soundDiv"style={{width:"20%"}}>
        <div style={{width:"40%"}}></div>
        <div>
             <SoundOutlined />
        </div>
        <Slider
          className="volume"
          defaultValue={100}
          tooltipVisible={false}
          onChange={(value) => onVolume(value/100)}
        />
      </div>
    </>




  )
}

export default AudioPlayer
