import { useEffect, useState } from "react"
import { useMoralis, useMoralisWeb3Api, useEnsName } from "react-moralis"

import * as localForage from 'localforage';


export const useTokensWOMetadata = () => {

    const { Moralis, web3, isInitialized,isAuthenticated } = useMoralis();

    const [tokensWOMetadata, setTokensWOMetadata] = useState();


  const getTokensWOMetadata = async () => {
  
    var tokens=[];

      if(isAuthenticated){
        tokens = await localForage.getItem("tokensWithoutMetadata");   
      }
      console.log( tokens, "tokensWithoutMetadata in useTokensWOMetadata");
    
      setTokensWOMetadata(tokens);
    
  };


  useEffect(() => {
    if (isInitialized) {
        getTokensWOMetadata().then((songs) => {
        //setAlbumDetails(songs)
        //console.log(songs , "setAlbumDetails was just set to songs.result");
      });
    }
  }, [isInitialized]);

  return { getTokensWOMetadata, tokensWOMetadata};
}
