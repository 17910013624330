import { useEffect, useState } from "react"
import { useMoralis, useMoralisWeb3Api, useEnsName } from "react-moralis"
import axios from 'axios';

import { Base64 } from 'js-base64';
import * as localForage from 'localforage';


export const useAlbum = (contractIn, typeIn, bypass) => {

  const { token } = useMoralisWeb3Api();
  const Web3Api = useMoralisWeb3Api();
  const { Moralis, web3, isInitialized } = useMoralis();

  const ethers = Moralis.web3Library;
  const provider = new ethers.providers.JsonRpcProvider(`https://mainnet.infura.io/v3/d6743f11cebc430db37958d45d2d49d5`);

  //var address = await provider.resolveName('alice.eth');
  const ignoreCase = require('ignore-case');


  const [albumDetails, setAlbumDetails] = useState();

  const Results = Moralis.Object.extend("ResultData");

  const d = new Date();
  let time = d.getTime();


  //console.log(contract, "contract")
  const fetchAlbum = async () => {

    var contract = contractIn
    // var contract = contractIn.toLowerCase();
    var type = typeIn

    


    var loopdata = [];
    var chainVar = "eth";
    // var chainVar = "rinkeby";
    var filterVar = "global";


    if (contract.includes(" CHAIN: POLYGON")) { chainVar = "polygon"; contract = contract.replace(" CHAIN: POLYGON", "") }
    if (contract.includes(" CHAIN: BSC")) { chainVar = "bsc"; contract = contract.replace(" CHAIN: BSC", "") }
    if (contract.includes(" FILTER: NAME")) { filterVar = "name"; contract = contract.replace(" FILTER: NAME", "") }
    if (contract.includes(" FILTER: DESCRIPTION")) { filterVar = "name"; contract = contract.replace(" FILTER: DESCRIPTION", "") }
    if (contract.includes(" FILTER: ATTRIBUTES")) { filterVar = "name"; contract = contract.replace(" FILTER: ATTRIBUTES", "") }

      // if (type == 2) {
    // if (contract.includes(".eth")) {
      //   contract = await provider.resolveName(contract);
      // }
      if (contract.includes(".ETH")) {
        contract = contract.toLowerCase();
        contract = await provider.resolveName(contract);
        contract = contract.toUpperCase();
        type=3;
      }
      
      if (type == 2) {

      console.log(contract,"contract shittt")
      console.log(contract.length,"contract shittt")
      // if (contract && contract.length == 42 && contract.slice(0, 2) == "0x") { type = 1
      if (contract && contract.length == 42 && ignoreCase.equals(contract.slice(0, 2),"0X")) { type = 1
        console.log(chainVar, "chainVar!", contract, "contract!", contract.length, "contract length", type, "type")
      };
      if (contract && contract.length == 42 && contract.slice(0, 2) == "0X") { type = 1
      };

    }
    // console.log(chainVar, "chainVar!", contract, "contract!", contract.length, "contract length", type, "type")


    var contractInTime;
    if (type == 3) {
      contractInTime = await localForage.getItem(contractIn+ "Time");
      console.log(contractInTime, "contractInTime")
      console.log(time,  "Time")
      console.log(time - contractInTime, "time - contractInTime")
    }

    if (type != 3 || type == 3 && contractInTime > 0 && time - contractInTime < 604200000) {
      // if (type != 2) {
      try {
        const value = await localForage.getItem(contractIn);
        // This code runs once the value has been loaded
        // from the offline store.
        console.log("localForage was found in useAlbum", value)
        // if (value.length > 0) {
        if (value) {
          setAlbumDetails(value)
          return value
        }
      } catch (err) {
        // This code runs if there were any errors.
        console.log(err, "error");
      }
      // }

      //
      //Begin Moralis Object part
      //
      const query = new Moralis.Query(Results);
      query.equalTo("contract", contractIn);
      // query.equalTo("contract", contractIn);
      const queryResults = await query.find();
      // .catch((error) => {
      //   console.error(error);
      // });
      var fullResults = [];
      // alert("Successfully retrieved " + queryResults.length + " gelgamek.");
      // if (bypass != true) {
      for (let i = 0; i < queryResults.length; i++) {
        const object = queryResults[i];
        const gelgamek = object.get("gelgamek");
        // alert(object.id + " - " + gelgamek);
        // console.log(queryResults[i], "query results");
        // console.log(object.id, " - gelgamek", gelgamek);

        fullResults = fullResults.concat(...gelgamek);
      }
      if (fullResults && fullResults.length > 0) {


        localForage.setItem(contractIn, fullResults).then(function (value) {
          console.log("localForage set in useAlbum top", value);
        })
        localForage.setItem(contractIn + "Time", time).then(function (value) {
          console.log("localForage time set in useAlbum top", value);
        })

        console.log("an object was found", fullResults, "fullResults of the Gelgameks")
        setAlbumDetails(fullResults)
        return fullResults;
      }
      // }
      //
      //End Moralis Object part
      //

    }



    function loop(cursor, endpoint) {

      var endpoint = [];
      var cursorEndpointString = [];
      endpoint[1] = `https://deep-index.moralis.io/api/v2/nft/${contract}?chain=${chainVar}&limit=100`;
      endpoint[2] = `https://deep-index.moralis.io/api/v2/nft/search?chain=${chainVar}&format=decimal&q=${contract}&filter=${filterVar}&limit=100`;
      endpoint[3] = `https://deep-index.moralis.io/api/v2/${contract}/nft?chain=${chainVar}&limit=100`;
      cursorEndpointString[1] = `https://deep-index.moralis.io/api/v2/nft/${contract}?chain=${chainVar}&cursor=${cursor}&limit=100`;
      cursorEndpointString[2] = `https://deep-index.moralis.io/api/v2/nft/search?chain=${chainVar}&format=decimal&q=${contract}&filter=${filterVar}&cursor=${cursor}&limit=100`;
      cursorEndpointString[3] = `https://deep-index.moralis.io/api/v2/${contract}/nft?chain=${chainVar}&cursor=${cursor}&limit=100`;


      endpoint = endpoint[type];  //console.log(endpoint, "endpoint")
      if (cursor) endpoint = cursorEndpointString[type];


      axios.get(endpoint, {
        headers: {
          'X-Api-Key': '15h9dfFzQOHZTjknBHefxcC7I4y7hRaUdxlMRDAa6oxHkNep9gv4EuBeeDt20ZLg'
        }
      })

        .then((response) => {

          //console.log(response, "for science")

          setTimeout(async () => {

            loopdata = loopdata.concat(response.data.result)

            if (loopdata.length == 0 && type == 1) {
              console.log(loopdata, type, "Loopdata is 0", "I gotta do some serious shit here")
              type = 3
              return loop(0);
            }

            if (bypass == true) {
              if (loopdata.length > 99 && loopdata.length < 199) {
                setAlbumDetails(loopdata)
              } else if (loopdata.length > 499 && loopdata.length < 599) {
                setAlbumDetails(loopdata)
              } else if (loopdata.length > 5099 && loopdata.length < 5198) {
                setAlbumDetails(loopdata)
              }
            }

            console.log("loopdata", loopdata.length)
            
            if (response.data.cursor) {
              
              
              if (typeIn == 2 && contractIn != localStorage.getItem("searchText").toUpperCase()) {
                
                if (!contractIn.includes(".ETH")) {
                  
                  // if(localStorage.getItem("searchText")!="undefined") {
                  if(localStorage.getItem("searchText")=="s") {
                    console.log("shit cake")
                    return;
                  }
          
                  }
                }
              
                
              loop(response.data.cursor);

            } else {

              console.log("finished", loopdata);


              // if (loopdata.length==0){setAlbumDetails(loopdata)
              //   return loopdata;}



              // try {
              //   const value = await localForage.getItem(forageKey);
              //   // This code runs once the value has been loaded
              //   // from the offline store.
        
              //   console.log("localForage was found in useFilter bot", value)
              //   // if (playlistDetails==value){return value}
              //   if (selectedTokens && selectedTokens.length == value.length) {
              //       setPlaylistDetails(value)
              //       return value
              //   }
              //   } catch (err) {
              //       // This code runs if there were any errors.
              //       console.log(err, "error");
              //   }


              //
              //Begin Moralis Object part
              //
              for (let i = 0; i <= (loopdata.length / 5000); i++) {
                var results = [];
                results[i] = new Results();
                results[i].set("contract", contractIn);
                // results[i].set("contract", contractIn);

                // results[i].set("gelgamek", loopdata.slice((i * 5000), ((i + 1) * 5000)));

                var iVar = i;
                if (loopdata.length % 5000 == 0) { iVar = i + 1 }
                if ((iVar) != (loopdata.length / 5000)) {
                  results[i].set("gelgamek", loopdata.slice((i * 5000), ((i + 1) * 5000)));
                } else {
                  results[i].set("gelgamek", loopdata.slice((i * 5000)));
                }

                results[i].save().then(
                  (result) => {
                    // Execute any logic that should take place after the object is saved.
                    // alert("New object created with objectId: " + result.id);
                  },
                  (error) => {
                    // Execute any logic that should take place if the save fails.
                    // error is a Moralis.Error with an error code and message.
                    alert("Failed to create new object, with error code: " + error.message);
                  }
                );
              }
              //
              //end Moralis Object part
              //


              localForage.setItem(contractIn, loopdata).then((value) => {
                console.log("localForage set in useAlbum bot", value);
              })
              localForage.setItem(contractIn + "Time", time).then((value) => {
                console.log("localForage time set in useAlbum bot", value);
              })

              // could set state
              setAlbumDetails(loopdata)
              return loopdata;
            }

          }, 150);

        });
    }

    //if (type !=2){
    //console.log(loop(0, endpoint1, cursorEndpointString1), "yoooo endpoints")
    return loop(0);
    // return loop(0);
    //}


  };



  useEffect(() => {
    if (isInitialized) {
      fetchAlbum().then((songs) => {
        //setAlbumDetails(songs)
        //console.log(songs , "setAlbumDetails was just set to songs.result");
      });
    }
  }, [isInitialized, contractIn]);

  return { fetchAlbum, albumDetails }
}





