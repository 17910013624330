import { useEffect, useState } from "react"
import _ from "lodash"

export const useSorter = (playlistDetails, sortTrait) => {



    const [sortedPlaylist, setSortedPlaylist] = useState();



    const sortPlaylist = async () => {

        let orderedTokens

        if (JSON.stringify(sortTrait) == "[]") {
            console.log(JSON.stringify(sortTrait), "sortTrait is this brah")
        } else {

            if (sortTrait == "token_id") {
                orderedTokens = _.orderBy(playlistDetails,
                    [item => { if (item.token_id < 10) { return "0" + item.token_id } else { return item.token_id } }], ["asc"]);

            } else {
                if (playlistDetails){
                var pushToTop = playlistDetails.map(item => {
                    let newItem2 = item
                    let newItem = JSON.parse(item.metadata)
                        newItem.attributes = newItem.attributes.sort((x, y) => (y.trait_type == sortTrait) - (x.trait_type == sortTrait))
                    newItem2.metadata = JSON.stringify(newItem)
                        return newItem2
                })}
                console.log(pushToTop, "sortTrait is pushed to the top!");


                orderedTokens = _.orderBy(pushToTop,
                    [item => JSON.parse(item.metadata).attributes.map(data => {
                        if (data.trait_type == sortTrait) {
                            let order;
                            if (data.value.length == 1) { order = "0"; order = order.concat(data.value); return order };
                            if (data.value.length >= 2) { order = data.value };
                            // console.log(order, "result");
                            return order
                        } else { return undefined }
                    })], ["asc"]);
            }


            
            //logs the trait type and value for the selected trait to sort by.
            // for (let i=0; i < orderedTokens.length; i++){
            //     console.log(JSON.parse(orderedTokens[i].metadata).attributes[0])
            // }
            

            if (JSON.stringify(orderedTokens) == "[]") {
                orderedTokens = undefined;
            }

            setSortedPlaylist(orderedTokens)

            return await orderedTokens

        }

        setSortedPlaylist(playlistDetails)

        return await playlistDetails
    };

    useEffect(() => {

        sortPlaylist().then((songs) => {
            setSortedPlaylist(songs)
        })

    }, [playlistDetails]);


    return { sortPlaylist, sortedPlaylist }
}
