import { useEffect, useState } from "react"
import { useMoralis } from "react-moralis"

import * as localForage from 'localforage';
import { soundXYZAbi } from "../helpers/soundXYZAbi";
import { wizardAbi } from "../helpers/contractWizardAbi";


export const useContractCount = () => {


    const [wizardContracts, setWizardContracts] = useState();
    const { Moralis } = useMoralis();

    const d = new Date();
    let time = d.getTime();

    async function contractCount() {


        const options = {
            // chain: "eth",
            chain: "rinkeby",
            address: "0x4539EFD776DA1832fDb0CabB7FdABa182FaC27DE",
            function_name: "contractCount",
            abi: wizardAbi,
            params: {},
        };

        const results = await Moralis.Web3API.native.runContractFunction(options);
        // console.log(results, "results")
        return results
        // return await Moralis.Web3API.native.runContractFunction(options);
    }


    async function getContractByID(input) {


        const options = {
            // chain: "eth",
            chain: "rinkeby",
            address: "0x4539EFD776DA1832fDb0CabB7FdABa182FaC27DE",
            function_name: "getContractByID",
            abi: wizardAbi,
            params: { contractId: input.toString() },
        };

        const results = await Moralis.Web3API.native.runContractFunction(options);
        // console.log(results, "results")
        return results
        // return await Moralis.Web3API.native.runContractFunction(options);
    }


    const getWizardContracts = async () => {

        const value = await localForage.getItem("wizardContracts");
        const valueTime = await localForage.getItem("wizardContracts" + "Time");


        if (time - valueTime < 2419200000) {
        // if (time - valueTime < 10) {
            if (value && value.length > 0) {
                console.log("found wizard contracts in localforage in usecontractcount", value)
                setWizardContracts(value)
                return value
            }
        }


        var loopdata = [];
        var listLength = await contractCount();
        console.log(listLength, "listLength from useContractCount")


        const Results = Moralis.Object.extend("contractWizard");
        const query = new Moralis.Query(Results);
        const queryResults = await query.find();

        if (queryResults.length == 1) {
            var contracts = queryResults[0].attributes.contracts
            console.log(queryResults[0].attributes, "queryResults in usecontractcount")
            // console.log(queryResults[0].attributes.contracts, "queryResults")
            if (contracts && contracts.length > 0 && contracts.length == listLength) {
                localForage.setItem("wizardContracts", contracts).then((value) => {
                    console.log("localForage set in useContractCount", value);
                })
                localForage.setItem("wizardContracts" + "Time", time).then((value) => {
                    console.log("localForage time set in useContractCount", value);
                })

                setWizardContracts(contracts)
                return contracts;
            }
        }


        // const contracts = await query.get("contracts");
        for (let i = 0; i <= queryResults.length; i++) {
            console.log(queryResults, "queryResults in useContractCount")
            localForage.removeItem('wizardContracts')
            localForage.removeItem('wizardContracts' + "Time")
            if (queryResults && i < queryResults.length) {
                // listLength = queryResults[i].attributes.listLength
                queryResults[i].destroy().then(
                    (myObject) => {
                        // The object was deleted from the Moralis Cloud.
                        console.log(myObject, "object was destroyed")
                    },
                    (error) => {
                        // The delete failed.
                    }
                )
            }
        }
        // console.log(contracts, "contracts")


        async function loop(count) {

            var results
            results = await getContractByID(count);
            console.log(results, "results in useContractCount");

            setTimeout(async () => {

                if (results && results.length == 42) {
                    loopdata = loopdata.concat(results)
                    // console.log(loopdata, count, "loopdata results")
                    console.log(count, "loopdata results in useContractCount")
                    if (count != (listLength - 1)) {
                        count++
                        loop(count)
                    } else if (count == (listLength - 1)) {
                        console.log("the end was reached in useContractCount")

                        var wizard
                        wizard = new Results();
                        wizard.set("listLength", listLength)
                        wizard.set("contracts", loopdata)
                        wizard.save().then(
                            (result) => {
                                console.log("saved contractWizard moralis object")
                            },
                            (error) => {

                            }
                        );

                        localForage.setItem("wizardContracts", loopdata).then((value) => {
                            console.log("localForage set in useContractCount", value);
                        })
                        localForage.setItem("wizardContracts" + "Time", time).then((value) => {
                            console.log("localForage time set in useContractCount", value);
                        })

                        setWizardContracts(loopdata)
                        return loopdata;
                    }
                } else {
                    setWizardContracts(loopdata)
                    return loopdata;
                }


            }, 150);

        }

        return loop(0);

    }

    useEffect(() => {

        console.log("got to useeffect")
        getWizardContracts();

    }, []);

    return { getWizardContracts, wizardContracts };
}
