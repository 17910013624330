import { useEffect, useState } from "react"
import { useMoralis } from "react-moralis"

import * as localForage from 'localforage';
import { abi } from "../helpers/soundXYZArtistAbi";


export const useSoundXYZContracts = (artistContracts) => {


    const [soundXYZURIs, setSoundXYZURIs] = useState();
    const { Moralis } = useMoralis();

    const d = new Date();
    let time = d.getTime();


    async function contractURI(input) {


        const options = {
            chain: "eth",
            address: input,
            function_name: "contractURI",
            abi: abi,
            params: {},
        };

        const results = await Moralis.Web3API.native.runContractFunction(options);
        // console.log(results, "results")
        return results
        // return await Moralis.Web3API.native.runContractFunction(options);
    }

    const getContractURIs = async () => {

        const value = await localForage.getItem("soundXYZContractURIs");
        const valueTime = await localForage.getItem("soundXYZContractURIs" + "Time");

        if (time - valueTime < 2419200000) {
            if (value && value.length > 0) {
                console.log("soundxyz contractURIs", value)
                setSoundXYZURIs(value)
                return value
            }
        }

        var loopdata = [];
        // var listLength = localStorage.getItem("soundXYZCount");
        // console.log(localStorage.getItem("soundXYZCount"), "localstorage")


        const Results = Moralis.Object.extend("soundXYZContractURIs");
        const query = new Moralis.Query(Results);
        const queryResults = await query.find();

        if (queryResults.length == 1) {
            var contractURIs = queryResults[0].attributes.contractURIs
            console.log(queryResults[0].attributes, "queryResults")
            // console.log(queryResults[0].attributes.contracts, "queryResults")
            if (contractURIs && contractURIs.length > 0) {
                localForage.setItem("soundXYZContractURIs", contractURIs).then((value) => {
                    console.log("localForage set in useSoundXYZContracts", value);
                })
                localForage.setItem("soundXYZContractURIs" + "Time", time).then((value) => {
                    console.log("localForage set in useSoundXYZContracts", value);
                })
                setSoundXYZURIs(contractURIs)
                return contractURIs;
            }
        }


        // const contracts = await query.get("contracts");
        for (let i = 0; i <= queryResults.length; i++) {
            console.log(queryResults, "queryResults")
            localForage.removeItem('soundXYZContractURIs')
            localForage.removeItem('soundXYZContractURIs' + "Time")
            if (queryResults && i < queryResults.length) {
                queryResults[i].destroy().then(
                    (myObject) => {
                        // The object was deleted from the Moralis Cloud.
                        console.log(myObject, "object was destroyed")
                    },
                    (error) => {
                        // The delete failed.
                    }
                )
            }
        }
        // console.log(contracts, "contracts")


        async function loop(count) {

            var results = "";
            if (artistContracts && artistContracts[count]) {
                results = await contractURI(artistContracts[count]);
            }
            var url
            // url = await contractURIs(artistContracts[count]);


            await fetch(results, {
                method: 'get'
            }).then(async function (response) {

                url = await response.json();
                console.log(url, "results here")
            }).catch(function (err) {
                // Error :(
            });



            setTimeout(async () => {

                // if (url && url.length > 0) {
                if (url) {
                    loopdata = loopdata.concat(url)
                    loopdata[count].contract = artistContracts[count]
                    loopdata[count].platform = "Sound XYZ";
                    count++
                    // console.log(loopdata, count, "loopdata results")
                    console.log(count, "loopdata results")
                    if (count != artistContracts.length) {
                        loop(count)
                    } else if (count == artistContracts.length) {
                        console.log("the end was reached")


                        var soundXYZ
                        soundXYZ = new Results();
                        soundXYZ.set("contractURIs", loopdata)
                        soundXYZ.save().then(
                            (result) => {
                                console.log("saved soundxyz moralis object")
                            },
                            (error) => {

                            }
                        );


                        localForage.setItem("soundXYZContractURIs", loopdata).then((value) => {
                            console.log("localForage set in useSoundXYZContracts", value);
                        })
                        localForage.setItem("soundXYZContractURIs" + "Time", time).then((value) => {
                            console.log("localForage set in useSoundXYZContracts", value);
                        })

                        setSoundXYZURIs(loopdata)
                        return loopdata;
                    }
                } else {
                    setSoundXYZURIs(loopdata)
                    return loopdata;
                }

                // console.log("the end was reached 2")
                // return loopdata

            }, 250);
            // console.log(results, "results2")
            // console.log(results.length, "results length")
        }

        return loop(0);

    }



    useEffect(() => {

        console.log("got to useeffect")
        getContractURIs();


    }, [artistContracts]);

    return { getContractURIs, soundXYZURIs };
}
