import { useEffect, useState } from "react"
import { useMoralis } from "react-moralis"

import * as localForage from 'localforage';


export const useFilter = (contractIn, selected, traitSelected, address, type, bypass) => {

    const { Moralis } = useMoralis();


    const ignoreCase = require('ignore-case');

    const [playlistDetails, setPlaylistDetails] = useState();


    const forageKey = address + JSON.stringify(selected) + JSON.stringify(traitSelected);

    const d = new Date();
    let time = d.getTime();

    const filterPlaylist = async () => {

        var tightSearch = true;
        var selectedTokens = contractIn;

        if (type != 2) {
            try {
                const value = await localForage.getItem(forageKey);
                const forageTime = await localForage.getItem(forageKey + "Time");
                // This code runs once the value has been loaded
                // from the offline store.
                console.log("localForage was found in useFilter", value)
                //checks if the storage is more than 4 weeks old
                if (time - forageTime < 2419200000) {
                    if (value.length > 0) {
                        setPlaylistDetails(value)
                        return value
                    }
                }
            } catch (err) {
                // This code runs if there were any errors.
                console.log(err, "error");
            }
        }



        //
        //Begin Moralis Object part
        //
        // const Results = Moralis.Object.extend("FilterData");
        // const query = new Moralis.Query(Results);

        // query.equalTo("contract", address.toUpperCase());
        // if (selected && selected.length > 0) {
        //     query.containsAll("selected", selected);
        // } else {
        //     query.doesNotExist("selected");
        // }
        // if (traitSelected && traitSelected.length > 0) {
        //     query.containsAll("traitSelected", traitSelected);
        // } else {
        //     query.doesNotExist("traitSelected");
        // }

        // var queryResults = await query.find();
        // var fullResults = [];
        // let resultsLength;

        // // alert("Successfully retrieved " + queryResults.length + " gelgamek.");
        // if (queryResults && queryResults.length > 0) {
        //     resultsLength = (5000 * (queryResults.length - 1)) + queryResults[queryResults.length - 1].attributes.gelgamek.length
        //     console.log("resultsVar bro", resultsLength)
        //     console.log("resultsVar brew", queryResults[queryResults.length - 1].attributes.gelgamek.length)
        //     console.log("resultsVar brewww", (5000 * (queryResults.length - 1)))
        // }

        // if (bypass != true) {
        //     for (let i = 0; i < queryResults.length; i++) {
        //         const object = queryResults[i];
        //         const gelgamek = object.get("gelgamek");
        //         // alert(object.id + " - " + gelgamek);
        //         console.log(queryResults[i], "query results");
        //         console.log(object.id, " - gelgamek", gelgamek);
        //         fullResults = fullResults.concat(...gelgamek);
        //         // setPlaylistDetails(fullResults)
        //     }
        //     if (fullResults && fullResults.length > 0) {

        //         // localForage.setItem(forageKey, fullResults).then(function (value) {
        //         //     console.log("localForage set in useFilter top", value);
        //         // })

        //         console.log("an object was found in useFilter top", fullResults, "fullResults of the Gelgameks")
        //         // if (playlistDetails != fullResults) {
        //         // setPlaylistDetails(fullResults.slice(0,100))
        //         // }
        //         // return fullResults;
        //     }
        // }


        //
        //End Moralis Object part
        //

        //console.log (selected, "selected variable inside usefilter")
        //console.log (traitSelected, "traitSelected variable inside usefilter")

        let contract = contractIn;


        if (!contractIn) { } else {

            //Dreamloops Test lol
            selectedTokens = contract.filter(element => {
                //console.log(!JSON.parse(element.metadata), "element")

                if (!element) { return }
                if (!element.metadata) { return }
                if (!JSON.parse(element.metadata).animation_url) { return }
                if (!JSON.parse(element.metadata).attributes) {
                    //console.log(JSON.parse(element.metadata), "element metadata");
                    let newElement = element
                    let newItem = JSON.parse(element.metadata)
                    newItem.attributes = []
                    newElement.metadata = JSON.stringify(newItem)
                    return newElement
                }
                return element
            });


            console.log(selectedTokens, "selectedTokens after filtering away the ones that don't have audio")
            contract = selectedTokens;

            if (traitSelected.length == 0 && selected.length == 0) { } else {
                if (tightSearch == true) {
                    var length; if (selected.length > traitSelected.length) { length = selected.length } else { length = traitSelected.length } for (let i = 0; i < length; i++) {
                        if (!traitSelected[i]) { selectedTokens = contract.filter(checkSelection1); function checkSelection1(token) { var ans; { JSON.parse(token.metadata).attributes.map(data => { if (ignoreCase.includes(data.value, selected[i])) { ans = true; console.log(selected[i], "selected", i) } }) } { return ans; } } contract = selectedTokens }
                        if (!selected[i]) { selectedTokens = contract.filter(checkSelection2); function checkSelection2(token) { var ans; { JSON.parse(token.metadata).attributes.map(data => { if (ignoreCase.includes(data.trait_type, traitSelected[i])) { ans = true; console.log(traitSelected[i], "traitSelected", i) } }) } { return ans; } } contract = selectedTokens }
                        if (selected[i] && traitSelected[i]) {
                            selectedTokens = contract.filter(checkSelection3); function checkSelection3(token) { var ans; { JSON.parse(token.metadata).attributes.map(data => { if (ignoreCase.includes(data.trait_type, traitSelected[i]) && ignoreCase.includes(data.value, selected[i])) { ans = true; console.log(selected[i], "selected", i, traitSelected[i], "traitSelected", i) } }) } { return ans; } }
                            if (selectedTokens == 0 && traitSelected[i] == "name") { selectedTokens = contract.filter(checkSelection1); function checkSelection1(token) { var ans; { if (ignoreCase.includes(JSON.parse(token.metadata).name, selected[i])) { ans = true; console.log(selected[i], "selected", i) } } { return ans; } } }; contract = selectedTokens
                        }
                    }
                } else {
                    selectedTokens = contract.filter(checkSelection); function checkSelection(token) {
                        var ans;
                        if (traitSelected.length == 0) { JSON.parse(token.metadata).attributes.map(data => { if (ignoreCase.includes(data.value, selected)) { ans = true; } }) }
                        if (selected.length == 0) { JSON.parse(token.metadata).attributes.map(data => { if (ignoreCase.includes(data.trait_type, traitSelected)) { ans = true; } }) }
                        JSON.parse(token.metadata).attributes.map(data => { if (ignoreCase.includes(data.trait_type, traitSelected) && ignoreCase.includes(data.value, selected)) { ans = true; } }); { return ans; }
                    }
                }
            }
        }


        try {
        const value = await localForage.getItem(forageKey);
        // This code runs once the value has been loaded
        // from the offline store.

        console.log("localForage was found in useFilter bot", value)
        // if (playlistDetails==value){return value}
        if (value && selectedTokens && selectedTokens.length == value.length) {
            setPlaylistDetails(value)
            return value
        }
        } catch (err) {
            // This code runs if there were any errors.
            console.log(err, "error");
        }

        //
        //Begin Moralis Object part
        //


        const Results = Moralis.Object.extend("FilterData");
        const query = new Moralis.Query(Results);

        query.equalTo("contract", address.toUpperCase());
        if (selected && selected.length > 0) {
            query.containsAll("selected", selected);
        } else {
            query.doesNotExist("selected");
        }
        if (traitSelected && traitSelected.length > 0) {
            query.containsAll("traitSelected", traitSelected);
        } else {
            query.doesNotExist("traitSelected");
        }

        var queryResults = await query.find();
        var fullResults = [];
        let resultsLength;

        // alert("Successfully retrieved " + queryResults.length + " gelgamek.");
        if (queryResults && queryResults.length > 0) {
            resultsLength = (5000 * (queryResults.length - 1)) + queryResults[queryResults.length - 1].attributes.gelgamek.length
            console.log("resultsVar bro", resultsLength)
            console.log("resultsVar brew", queryResults[queryResults.length - 1].attributes.gelgamek.length)
            console.log("resultsVar brewww", (5000 * (queryResults.length - 1)))
        }

        if (bypass != true) {
            if (selectedTokens && resultsLength == selectedTokens.length) {
                // fullResults = [];
                for (let i = 0; i < queryResults.length; i++) {
                    const object = queryResults[i];
                    const gelgamek = object.get("gelgamek");
                    // alert(object.id + " - " + gelgamek);
                    // console.log(queryResults[i], "query results");
                    console.log(object.id, " - gelgamek", gelgamek);
                    fullResults = fullResults.concat(...gelgamek);
                    // setPlaylistDetails(fullResults)
                }
                if (fullResults && fullResults.length > 0) {

                    localForage.setItem(forageKey, fullResults).then(function (value) {
                        console.log("localForage set in useFilter bot", value);
                    })

                    localForage.setItem(forageKey + "Time", time).then(function (value) {
                        console.log("localForage Time set in useFilter bot", value);
                    })

                    console.log("an object was found in useFilter bot", fullResults, "fullResults of the Gelgameks")
                    if (playlistDetails != fullResults) {
                        setPlaylistDetails(fullResults)
                    }
                    return fullResults;
                }

            }
        }

        if (selectedTokens) {
            for (let i = 0; i <= queryResults.length; i++) {
                if (queryResults && i < queryResults.length) {
                    queryResults[i].destroy().then(
                        (myObject) => {
                            // The object was deleted from the Moralis Cloud.

                        },
                        (error) => {
                            // The delete failed.
                            // error is a Moralis.Error with an error code and message.
                        }
                    )
                    // gelgamek = queryResults[i].get("gelgamek");
                    console.log("Magic you got here")
                    console.log("Magic results length", resultsLength)
                    console.log("Magic query results length", queryResults.length)
                    console.log("Magic selectedtokens length", selectedTokens.length)
                    console.log("Magic contractIn length", contractIn.length)
                }
            }

            for (let i = 0; i <= (selectedTokens.length / 5000); i++) {
                let gelgamek;

                if (!gelgamek || gelgamek.length < 5000) {

                    var results = [];
                    results[i] = new Results();
                    results[i].set("contract", address.toUpperCase());
                    if (selected && selected.length > 0) {
                        results[i].set("selected", selected);
                    }
                    if (traitSelected && traitSelected.length > 0) {
                        results[i].set("traitSelected", traitSelected);
                    }
                    var iVar = i;
                    if (selectedTokens.length % 5000 == 0) { iVar = i + 1 }
                    if ((iVar) != (selectedTokens.length / 5000)) {
                        results[i].set("gelgamek", selectedTokens.slice((i * 5000), ((i + 1) * 5000)));
                    } else {
                        results[i].set("gelgamek", selectedTokens.slice((i * 5000)));
                    }
                    results[i].save().then(
                        (result) => {
                            // Execute any logic that should take place after the object is saved.
                            // alert("New object created with objectId: " + result.id);
                        },
                        (error) => {
                            // Execute any logic that should take place if the save fails.
                            // error is a Moralis.Error with an error code and message.
                            alert("Failed to create new object, with error code: " + error.message);
                        }
                    );

                }
            }

            //
            //end Moralis Object part
            //

            localForage.setItem(forageKey, selectedTokens).then(function (value) {
                console.log("localForage set in useFilter bot bot", value);
            })

            localForage.setItem(forageKey + "Time", time).then(function (value) {
                console.log("localForage Time set in useFilter bot bot", value);
            })

            // console.log(selectedTokens, "All sorted")
            if (playlistDetails != selectedTokens) {
                setPlaylistDetails(selectedTokens)
            }

            return await selectedTokens
        }
        //return await selectedTokens.then((result) => result);

    };

    var oldContractIn;

    useEffect(() => {
        if (contractIn != oldContractIn) {
            // if (!playlistDetails) {
                console.log("yo bruv useEffect innit")
                oldContractIn = contractIn;
                filterPlaylist();
            // }
        }
        //return console.log("yo bruv useEffect innit")
    }, [contractIn]);
    // }, [address]);


    return { filterPlaylist, playlistDetails }
}
