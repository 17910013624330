import React from 'react';
import { useState, useRef, useEffect } from "react";
import { Routes, Route, Link, useNavigate, useSearchParams } from "react-router-dom";
import Home from "./pages/Home";
import Album from './pages/Album';
import Library from './pages/Library';
import Create from './pages/Create';
import About from "./pages/About";
import './App.css';
import { Layout, Menu, Dropdown, Space } from 'antd';

import { SearchOutlined, DownCircleOutlined, MenuUnfoldOutlined, MenuFoldOutlined, HomeOutlined, BookOutlined, PlusOutlined, QuestionOutlined, LoginOutlined } from "@ant-design/icons";
import AudioPlayer from "./components/AudioPlayer";

import { useMoralisWeb3Api, useMoralis } from "react-moralis";


const Opensea = "https://arweave.net/t_sIwpq8gHura4FI3D2dz7AIwG97KL7inExQXbPWIDg";


const ApeTapesText = "https://arweave.net/HGooryY2LboiSm68-EnV42EMFYHD2ltiXd4d0JkGx1k";
const BoredToDeathTape = "https://arweave.net/NjnUNmyVQTZ3KNob-mOc8sK7sIcrhN1es6Pyo3OnBjU";
const BenApeTape ="https://arweave.net/fywJXh2FHI6rusySW5bSc0EcQNT78m0JvBKluY3Nyxg";
const BoltBoy = "https://arweave.net/U0L4WMzuYw8KrezHeY9wtbBM5YY5CPejjjUxLDgvvRs";
const Mutant = "https://arweave.net/XX65RwStpfRnHHIJiNod8PjDjDKdRGMMeyO91dRHYR8";
const Combined = "https://arweave.net/Lzj75zHvYfYB-YWX0rdEG-qQQO3NIV21UTkdnzGwkEE";


const { Footer, Sider, Content } = Layout;


const App = () => {
  const [nftAlbum, setNftAlbum] = useState();
  const [songChoice, setSongChoice] = useState();

  const searchText = useRef();
  const [isSearch, setIsSearch] = useState();

  const Web3Api = useMoralisWeb3Api();
  const { Moralis, authenticate, isAuthenticated, isAuthenticating, logout, user } = useMoralis();


  let navigate = useNavigate();


  // console.log (window.location.hash, "location hash"  )

  const [searchParams, setSearchParams] = useSearchParams();


  var walletAddress = user && user.get("ethAddress")

  if (user) {
    walletAddress = user.get("ethAddress");
  } else {
    walletAddress = "";
  }



  // useEffect(async () => {

    // setSearchParams({nuts:"deeznutsssss"})
    // setSearchParams({ nuts: "deeznutsssss", wallet: walletAddress })

    // // if (first!=true){
    //   // first = true;
    //   navigate ('/')
    // // }

  // }, [walletAddress]);



  const onIconClick = (ev) => {
    setIsSearch(true);
    if (!searchText.current) {
      searchText.current = localStorage.getItem("oldSearchText")
    }
    console.log(ev, "what is this?", searchText.current, "searchText")
    localStorage.setItem("oldSearchText", searchText.current);


    // navigate('/album')
    // navigate('/play')
    navigate({
      pathname: '/play',
      search: `?search=${searchText.current}`,
    })
  }

  const onWalletClick = (ev) => {
    setIsSearch(false);

    // navigate('/album')

    navigate({
      pathname: '/library',
      search: `?wallet=${walletAddress}`,
    })


  }


  const [collapsed, setCollapsed] = useState(false);



  var sideHeight = ""
  if (nftAlbum) { sideHeight = "" }
  var sideIcon = <img src={BoltBoy} onClick={() => setCollapsed((prev) => !prev)} alt="Logo" className="logo" style={{ marginRight: "-25px" }}></img>
  var apeTapesText = <img src={ApeTapesText} alt="Logo" className="logo2"></img>
  var sideIcon2 = <img src={Mutant} alt="Logo" className="logo" style={{ marginLeft: "-35px" }}></img>

  var benApeTapeIcon = <img src={BenApeTape} alt="Logo" className="logo2" style={{}}></img>
  var boredToDeathIcon = <img src={BoredToDeathTape} alt="Logo" className="logo2" style={{ margin: "70px", marginBottom: "200px" }}></img>

  if (collapsed) {
    apeTapesText = ""; sideIcon2 = "";
    benApeTapeIcon = "";
    boredToDeathIcon = <img src={BoredToDeathTape} alt="Logo" className="logo2" style={{ margin: "0px", width: "50px" }}></img>;
    sideIcon = <img src={Combined} onClick={() => setCollapsed((prev) => !prev)} alt="Logo" className="logo3" ></img>
  }


  localStorage.setItem("searchText", searchText.current);
  console.log(localStorage.getItem("searchText"), "got from local storage");


  var searchBar = <input
    id="myInput"
    type="text"
    placeholder="Search..."
    //value={searchText}
    style={{ width: "240px", marginRight: "-30px" }}
    onChange={e => searchText.current = e.target.value}
    onKeyPress={(ev) => {
      if (ev.key === "Enter") {
        ev.preventDefault();
        console.log(ev.target.value);
        setIsSearch(true);
        searchText.current = ev.target.value;

        localStorage.setItem("oldSearchText", searchText.current);


        // navigate('/album')
        // navigate('/play')
        navigate({
          pathname: '/play',
          search: `?search=${searchText.current}`,
        })
      }
    }} />

  if (collapsed) { searchBar = "" }


  var homeSide
  if (collapsed) { homeSide = <HomeOutlined /> }
  else { homeSide = "Home" }

  var librarySide
  if (collapsed) { librarySide = <BookOutlined /> }
  else { librarySide = "Library" }

  var createSide
  if (collapsed) { createSide = <PlusOutlined /> }
  else { createSide = "Create" }

  var aboutSide
  if (collapsed) { aboutSide = <QuestionOutlined /> }
  else { aboutSide = "About" }

  var recentlyPlayedSide = <p className="recentTitle">RECENTLY PLAYED</p>
  if (collapsed) { recentlyPlayedSide = "" }


  const [bottomText3, setBottomText] = useState();


  if (!isAuthenticated) {

    bottomText =
      <>
        <div className="albums">
          <container >
            <button onClick={() => authenticate({ signingMessage: "Log in to ApeTapes" })} className="sideLogIn" >
              <LoginOutlined /> Log In
            </button>

          </container>
        </div>
        <div className="container" >
        </div>
      </>

  }



  const fetchAddress = async () => {

    if (bottomText3 && bottomText3.includes(".eth")) {
      bottomText = user.get("ethAddress").slice(0, 5) + "..." + user.get("ethAddress").slice(-4);
      setBottomText(bottomText);
      console.log("its done")
      return
    }

    if (bottomText3 == user.get("ethAddress").slice(0, 5) + "..." + user.get("ethAddress").slice(-4)) {

      const options = { address: user.get("ethAddress") };
      const resolve = await Web3Api.resolve.resolveAddress(options);
      bottomText = resolve.name;
      setBottomText(resolve.name);
      console.log("clicked the button inside the top if")

    }
    // get ENS domain of an address
    const options = { address: user.get("ethAddress") };
    const resolve = await Web3Api.resolve.resolveAddress(options);

    bottomText = resolve.name;
    setBottomText(resolve.name);
    console.log("clicked the button")
    // fetchAddress();
  };


  if (user) {
    if (user.get("ethAddress")) {

      bottomText = user.get("ethAddress").slice(0, 5) + "..." + user.get("ethAddress").slice(-4);


    }

  }

  const clickHome = () => {

    // if (searchText.current =="s"){
      // searchText.current ="";
    // }else{
      searchText.current = "s";
      console.log("ping1", searchText.current, "searchText")
      // }
      setTimeout( () => {
        searchText.current = "";
        console.log("ping2", searchText.current, "searchText")
      }, 1000);


  }




  var bottomText

  var bottomText2 = <DownCircleOutlined style={{ fontSize: "30px" }} />


  if (collapsed) { bottomText = ""; bottomText2 = ""; if (bottomText3 != "") { setBottomText("") } }

  var sideLink = "sideLink";
  if (collapsed) { sideLink = "p-link" }


  //
  //touch input recognition code
  const [touchStart, setTouchStart] = useState(null)
  const [touchEnd, setTouchEnd] = useState(null)

  // the required distance between touchStart and touchEnd to be detected as a swipe
  const minSwipeDistance = 50

  const onTouchStart = (e) => {
    setTouchEnd(null) // otherwise the swipe is fired even with usual touch events
    setTouchStart(e.targetTouches[0].clientX)
  }

  const onTouchMove = (e) => setTouchEnd(e.targetTouches[0].clientX)

  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return
    const distance = touchStart - touchEnd
    const isLeftSwipe = distance > minSwipeDistance
    const isRightSwipe = distance < -minSwipeDistance
    if (isLeftSwipe || isRightSwipe) console.log('swipe', isLeftSwipe ? 'left' : 'right')
    if (isLeftSwipe) { setCollapsed(true) }
    if (isRightSwipe) { setCollapsed(false) }
  }

  //



  return (


    <Layout>
      <Layout>
        <Sider trigger={null} collapsible collapsed={collapsed} width={300} collapsedWidth={60} className="sideBar" onTouchStart={onTouchStart} onTouchMove={onTouchMove} onTouchEnd={onTouchEnd} >

          {sideIcon}
          {apeTapesText}
          {sideIcon2}

          <div className="searchBar">
            <span>
              <SearchOutlined className="searchIcon" style={{ fontSize: "30px", marginLeft: "8px" }} onClick={onIconClick} />
              {searchBar}
            </span>

          </div>
          <Link to="/">
            <p style={{ color: "#cf6712" }} className={sideLink} onClick={() => clickHome()}> {homeSide}</p>
          </Link>
          {/* <Link to="/library" onClick={onWalletClick} > */}
          {/* <Link to={`/library/${walletAddress}`}  onClick={onWalletClick} > */}
          {/* <Link to={{
            pathname: `/library/${walletAddress}`,
            search: `?wallet=${walletAddress}&order=newest`,
            hash: "#the-hash",
            state: { fromDashboard: true }
          }} onClick={onWalletClick} >
            <p style={{ color: "#8400d0" }} className={sideLink}> {librarySide} </p>
          </Link> */}
          <Link to={{
            pathname: `/library`,
            search: `?wallet=${walletAddress}`,
          }} onClick={onWalletClick} >
            <p style={{ color: "#8400d0" }} className={sideLink}> {librarySide} </p>
          </Link>
          <Link to="/create">
            <p style={{ color: "#8400d0" }} className={sideLink}> {createSide} </p>
          </Link>
          <Link to="/about" >
            <p style={{ color: "#8400d0" }} className={sideLink}> {aboutSide} </p>
          </Link>
          {/* <div className="recentPlayed">
            {recentlyPlayedSide}
          </div> */}
          <div className="install">
            {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
              className: 'sideBarButton',
              onClick: () => setCollapsed((prev) => !prev),
            })}
            <div onClick={() => fetchAddress()} > {bottomText3 ? bottomText3 : bottomText}</div>
            {/* <div  onClick={() => fetchAddress()} > { bottomText}</div>  */}

            <div >{bottomText2}</div>
          </div>
          {/* <img src={BenApeTape} alt="Logo" className="logo2" style={{ }}></img> */}
          {boredToDeathIcon}

        </Sider>

        <Content className="contentWindow">
          <Routes>
            <Route path="/" element={<Home setIsSearch={setIsSearch} />} />
            {/* <Route path="/album" element={<Album setNftAlbum={setNftAlbum} setSongChoice={setSongChoice} isSearch={isSearch} searchText={searchText.current} />} /> */}
            <Route path="/play" element={<Album setNftAlbum={setNftAlbum} setSongChoice={setSongChoice} isSearch={isSearch} searchText={searchText.current} />} />
            <Route path="/play/:search" element={<Album setNftAlbum={setNftAlbum} setSongChoice={setSongChoice} isSearch={isSearch} searchText={searchText.current} />} />
            <Route path="/library/" element={<Library setNftAlbum={setNftAlbum} setSongChoice={setSongChoice} isSearch={isSearch} setBottomText={setBottomText} />} />
            <Route path="/library/:wallet" element={<Library setNftAlbum={setNftAlbum} setSongChoice={setSongChoice} isSearch={isSearch} setBottomText={setBottomText} />} />
            <Route path="/create" element={<Create />} />
            <Route path="/create/:creation" element={<Create />} />
            <Route path="/about" element={<About />} />
          </Routes>
        </Content>

      </Layout>
      <Footer className="footer" >
        {nftAlbum &&
          <AudioPlayer nftAlbum={nftAlbum} songChoice={songChoice} />}
      </Footer>
    </Layout>

  )
};

export default App;
