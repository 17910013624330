export const blacklist = [
  {
    title: "Ribbits",
    contract: "0x3de58785E9D021e9b2f3713717730B365309aA89"
  },
  {
    title: "Kamagang",
    contract: "0xD049Ab4988962c8D3F25908E25baf7dd868A01C3"
  },
  {
    title: "cryptorunners",
    contract: "0xD05f71067876A68336c836aE602981728034a84c"
  },
  {
    title: "Ape Mafia ID (Deprecated)",
    contract: "0x180c8b3260b786c1e6191883be5385f0117737B9"
  },
  {
    title: "Dreamer VX ",
    contract: "0x5762F422feC2eB15f77a186cd22ef0de74eb7d5E"
  },
  {
    title: "Voxodeus",
    contract: "0xafbA8C6B3875868a90E5055e791213258a9fe7a7"
  }, 
  {
    title: "zenft Bonsai",
    contract: "0xeC9C519D49856Fd2f8133A0741B4dbE002cE211b"
  }, 
  {
    title: "Lamelo Ball",
    contract: "0x139B522955D54482E7662927653ABb0bFB6F19BA"
  }, 
  {
    title: "Goobers",
    contract: "0x000E49C87d2874431567d38FF9548890aB39BAac"
  }, 

];
