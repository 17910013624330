import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import "./Home.css";
import { Tabs, Card, Collapse } from "antd";
import { library } from "../helpers/albumList";

import { useMoralis, useMoralisWeb3Api } from "react-moralis";

import { useArtistContracts } from "../hooks/useArtistContracts";
import { useSoundXYZContracts } from "../hooks/useSoundXYZContracts";
import { useContractCount } from "../hooks/useContractCount";
import { useWizardContracts } from "../hooks/useWizardContracts";
import { soundXYZAbi } from "../helpers/soundXYZAbi";
import * as localForage from 'localforage';


// const ApeTapesText = "https://arweave.net/0RH8jPbjagMdPOgtJkqnRTUmegHBRJNMhIJkQ2ZsEYs";
const ApeTapesText = "https://arweave.net/HGooryY2LboiSm68-EnV42EMFYHD2ltiXd4d0JkGx1k";


const { TabPane } = Tabs;
const { Panel } = Collapse;



const Home = ({ setIsSearch, collapsed }) => {


  const { native } = useMoralisWeb3Api();
  const { Moralis, user } = useMoralis();



  const [refreshSoundXYZContractsDone, setRefreshSoundXYZContractsDone] = useState();
  const [deleteSoundXYZURIsDone, setDeleteSoundXYZURIsDone] = useState();
  const [deleteContractCountDone, setDeleteContractCountDone] = useState();
  const [deleteWizardURIsDone, setDeleteWizardURIsDone] = useState();


  const { artistContracts } = useArtistContracts();
  // console.log(artistContracts, "artistContracts")

  const { soundXYZURIs } = useSoundXYZContracts(artistContracts);
  // console.log(soundXYZURIs, "soundXYZURIs")

  const { wizardContracts } = useContractCount();
  console.log(wizardContracts, "wizardContracts")

  const { wizardURIs } = useWizardContracts(wizardContracts);
  console.log(wizardURIs, "wizardURIs")


  //
  //
  async function artistContractsFunction(input) {

    var inputText = "<input>"

    const options = {
      chain: "eth",
      address: "0x78E3aDc0E811e4f93BD9F1f9389b923c9A3355c2",
      function_name: "artistContracts",
      abi: soundXYZAbi,
      params: { '': input.toString() },
    };

    const results = await Moralis.Web3API.native.runContractFunction(options);
    // console.log(results, "results")
    return results
    // return await Moralis.Web3API.native.runContractFunction(options);
  }

  const getSoundXYZCount = async () => {



    const Results = Moralis.Object.extend("soundXYZ");
    const query = new Moralis.Query(Results);
    const queryResults = await query.find();

    localForage.removeItem('soundXYZContracts')

    for (let i = 0; i <= queryResults.length; i++) {
      console.log(queryResults, "queryResults")
      if (queryResults && i < queryResults.length) {
        queryResults[i].destroy().then(
          (myObject) => {
            // The object was deleted from the Moralis Cloud.
            console.log(myObject, "object was destroyed")
          },
          (error) => {
            // The delete failed.
          }
        )
      }
    }

    async function loop(count) {
      var results = await artistContractsFunction(count);
      setTimeout(async () => {
        if (results && results.length == 42) {
          count++
          console.log(count, "loopdata results")
          localStorage.setItem("soundXYZCount", count);
          console.log(localStorage.getItem("soundXYZCount"), "localstorage")
          loop(count)
        }
      }, 50);
    }

    setRefreshSoundXYZContractsDone(true);

    return loop(0);
  }
  //
  //
  //


  const deleteSoundXYZContractURIs = async () => {

    const Results = Moralis.Object.extend("soundXYZContractURIs");
    const query = new Moralis.Query(Results);
    const queryResults = await query.find();

    localForage.removeItem('soundXYZContractURIs')
    localForage.removeItem('soundXYZContractURIs' + 'Time')

    for (let i = 0; i <= queryResults.length; i++) {
      console.log(queryResults, "queryResults")
      if (queryResults && i < queryResults.length) {
        queryResults[i].destroy().then(
          (myObject) => {
            // The object was deleted from the Moralis Cloud.
            console.log(myObject, "object was destroyed")
          },
          (error) => {
            // The delete failed.
          }
        )
      }
    }

    setDeleteSoundXYZURIsDone(true);

  }


  const deleteWizardContractCount = async () => {

    const Results = Moralis.Object.extend("contractWizard");
    const query = new Moralis.Query(Results);
    const queryResults = await query.find();

    localForage.removeItem('wizardContracts')
    localForage.removeItem('wizardContracts' + 'Time')

    for (let i = 0; i <= queryResults.length; i++) {
      console.log(queryResults, "queryResults")
      if (queryResults && i < queryResults.length) {
        queryResults[i].destroy().then(
          (myObject) => {
            // The object was deleted from the Moralis Cloud.
            console.log(myObject, "object was destroyed")
          },
          (error) => {
            // The delete failed.
          }
        )
      }
    }

    setDeleteContractCountDone(true);

  }


  const deleteWizardContractURIs = async () => {

    const Results = Moralis.Object.extend("wizardContractURIs");
    const query = new Moralis.Query(Results);
    const queryResults = await query.find();

    localForage.removeItem('wizardContractURIs')
    localForage.removeItem('wizardContractURIs' + 'Time')

    for (let i = 0; i <= queryResults.length; i++) {
      console.log(queryResults, "queryResults")
      if (queryResults && i < queryResults.length) {
        queryResults[i].destroy().then(
          (myObject) => {
            // The object was deleted from the Moralis Cloud.
            console.log(myObject, "object was destroyed")
          },
          (error) => {
            // The delete failed.
          }
        )
      }
    }

    setDeleteWizardURIsDone(true);

  }


  var walletAddress;
  if (user) {
    walletAddress = user.get("ethAddress")
  }
  var soundXYZRefreshContractsCode;
  var soundXYZDeleteContractURIsCode;
  var wizardDeleteContractsCode;
  var wizardDeleteContractURIsCode;

  if (refreshSoundXYZContractsDone != true && walletAddress && walletAddress.toUpperCase() == "0xc737639FE05d27Bc7FCD8e3372f85C3dd2b3d1C1".toUpperCase()) {
    soundXYZRefreshContractsCode = <button className="logIn" style={{ width: "200px" }} onClick={() => { getSoundXYZCount() }}>Get count and contracts</button>
  }
  if (deleteSoundXYZURIsDone != true && walletAddress && walletAddress.toUpperCase() == "0xc737639FE05d27Bc7FCD8e3372f85C3dd2b3d1C1".toUpperCase()) {
    soundXYZDeleteContractURIsCode = <button className="logIn" style={{ width: "250px" }} onClick={() => { deleteSoundXYZContractURIs() }}>reset SoundXYZ Contract URIs</button>
  }
  if (deleteContractCountDone != true && walletAddress && walletAddress.toUpperCase() == "0xc737639FE05d27Bc7FCD8e3372f85C3dd2b3d1C1".toUpperCase()) {
    wizardDeleteContractsCode = <button className="logIn" style={{ width: "200px" }} onClick={() => { deleteWizardContractCount() }}>Reset Wizard Count</button>
  }
  if (deleteWizardURIsDone != true && walletAddress && walletAddress.toUpperCase() == "0xc737639FE05d27Bc7FCD8e3372f85C3dd2b3d1C1".toUpperCase()) {
    wizardDeleteContractURIsCode = <button className="logIn" style={{ width: "250px" }} onClick={() => { deleteWizardContractURIs() }}>Reset Wizard Contract URIs</button>
  }


  return (
    <>
      <Tabs defaultActiveKey="1" centered  >
        <TabPane tab="FEATURED" key="1">
          <h1 className="featuredTitle" >Welcome to<img src={ApeTapesText} alt="Logo" className="logo2"></img></h1>
          <div className="albums">
            {library.map((e, index) => (
              // <Link key={index} to="/album" state={e} className="albumSelection" onClick={setIsSearch(false)}>
              // <Link key={index} to="/play" state={e} className="albumSelection" onClick={setIsSearch(false)}>
              <Link key={index} to={{
                pathname: `/play`,
                search: `?search=${e && e.contract}`,
              }} state={e} className="albumSelection" onClick={setIsSearch(false)} >
    
              <img
                  src={e.image}
                  className="img"
                  alt="bull"
                  style={{ marginBottom: "10px" }}
                ></img>
                <p className="titleText">{e.title}</p>
              </Link>
            ))}
          </div>
        </TabPane>
        <TabPane tab="BROWSE" key="2">
          <h1 className="featuredTitle">Ape Tapes!</h1>
          <div className="text">

            
            <div>Browse contracts made from the Ape Tapes Contract Wizard. </div>
            <div>Create a contract that you own with the Contract Wizard.</div>
            <div>Everything in the wizard's library is part of a decentralized music nft directory.  </div> 
           
   {wizardDeleteContractsCode}
            {wizardDeleteContractURIsCode}

            <div className="albums">

              {wizardContracts && wizardContracts.map((wizard, i) => {

                var state;
                var stuff
                if (wizard && wizardURIs) {
                  state = wizardURIs[i];
                }

                var wizardImageCode = <div><img
                  src={state && state.image}
                  className="img"
                  alt="bull"
                  style={{ marginBottom: "10px" }}
                ></img>
                  <p className="titleText">{state && state.name}</p></div>
                console.log( state , "state")
                if (state && !state.image) {
                  wizardImageCode = ""
                }

                return (
                  <div>
                    {/* <Link key={i} to="/album" state={state} className="albumSelection" onClick={setIsSearch(false)} > */}
                    {/* <Link key={i} to="/play" state={state} className="albumSelection" onClick={setIsSearch(false)} > */}
                    <Link key={i} to={{
            pathname: `/play`,
            search: `?search=${state && state.contract}`,
          }} state={state} className="albumSelection" onClick={setIsSearch(false)} >

                      {wizardImageCode}

                    </Link>

                  </div>
                )
              })}
            </div>



            <h3 className="featuredTitle">Sound XYZ Library</h3>


            {soundXYZRefreshContractsCode}
            {soundXYZDeleteContractURIsCode}

            <div className="albums">

              {artistContracts && artistContracts.map((artist, i) => {

                var state;
                var stuff
                if (artist && soundXYZURIs) {
                  state = soundXYZURIs[i];
                }

                var soundXYZImageCode = <div><img
                  src={state && state.image}
                  className="img"
                  alt="bull"
                  style={{ marginBottom: "10px" }}
                ></img>
                  <p className="titleText">{state && state.name}</p></div>
                // console.log( stuff , "state")
                if (state && !state.image) {
                  soundXYZImageCode = ""
                }

                return (
                  <div>
                    {/* <Link key={i} to="/album" state={state} className="albumSelection" onClick={setIsSearch(false)} > */}
                    {/* <Link key={i} to="/play" state={state} className="albumSelection" onClick={setIsSearch(false)} > */}
                    <Link key={i} to={{
            pathname: `/play`,
            search: `?search=${state && state.contract}`,
          }} state={state} className="albumSelection" onClick={setIsSearch(false)} >


                      {soundXYZImageCode}

                    </Link>

                  </div>
                )
              })}
            </div>



          </div>
        </TabPane>

      </Tabs>


    </>
  )
}

export default Home;
