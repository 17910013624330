export const library = [
  {
    title: "We Love You",
    image: "https://arweave.net/5w-RSZlSdE0_4kdWmVtiWwmX32EMzDwGqY8u3KOJMU4",
    contract: "0x24571c6DB7919a4AfB8A2953a4f5287d16fE496E",
    openSea: "https://opensea.io/collection/desante-records",
    selection: "Genesis",
    selection2: "We Love You",
    traitSelection: "Rarity",
    sortTrait: "Track Number"
  },
  {
    title: "Boi Womyn Man Gyrl",
    image: "https://arweave.net/DQOw3GP7jhAsGgd8twD8vPxl4AnWCnbGPY0Ebb_2guo",
    contract: "0x24571c6DB7919a4AfB8A2953a4f5287d16fE496E",
    openSea: "https://opensea.io/collection/desante-records",
    selection: "Genesis",
    selection2: "Cosmo Doris",
    traitSelection: "Rarity",
    sortTrait: "Track Number"
  },
  {
    title: "Burn",
    image: "https://arweave.net/DtPqSwKFOczWv9Q-7X3qlgEAWJ4o9PvTsIbwxwg7vmM",
    contract: "0x24571c6DB7919a4AfB8A2953a4f5287d16fE496E",
    openSea: "https://opensea.io/collection/desante-records",
    selection: "Genesis",
    selection2: "City Stranger",
    traitSelection: "Rarity",
  },
  {
    title: "Dreamloops",
    image:
      "https://lh3.googleusercontent.com/4k4hX_GGIDocSxc91M6Ak-2mqpqzW6T266r-LlivahH6pgNmT1MRy8_H6UZX8UPDODJ_HMvTXqE9P8DXIeLiuBp9t3m7NF-ck8Xn=s0",
    contract: "0xf1B33aC32dbC6617f7267a349be6ebb004FeCcff",
    openSea: "https://opensea.io/collection/dreamloops",
  },
  {
    title: "Dreamloops Chordy Moms",
    image:
      "https://lh3.googleusercontent.com/4k4hX_GGIDocSxc91M6Ak-2mqpqzW6T266r-LlivahH6pgNmT1MRy8_H6UZX8UPDODJ_HMvTXqE9P8DXIeLiuBp9t3m7NF-ck8Xn=s0",
    contract: "0xf1B33aC32dbC6617f7267a349be6ebb004FeCcff",
    openSea: "https://opensea.io/collection/dreamloops",
    selection: "Chordy Mom",
    sortTrait: "song_title"
  },
  {
    title: "The Dreamers",
    image:
      "https://lh3.googleusercontent.com/QAtEMupNEgzlbi8ipV6tCJJV4nIjCqLMOIQgP38Yx6US46Ma64N-1OXaOxV0ZJ0ZtChxNc4OHBuVwxxo_7ziV95AtwAQJJhffcdurA=s0",
    contract: "0x10064373e248Bc7253653cA05Df73CF226202956",
    openSea: "https://opensea.io/collection/thedreamers"
  },
  {
    title: "WhoWho's Treehouse",
    image:
      "https://lh3.googleusercontent.com/Lp_JrM4aUwIy4Yl9Ov2ugTKsAFL0N5_YTTsYi8eyJt6mnuhXObMULNVHAvG_U1egkngtPK1l9zLYhHw5XlWtO4QLkvMS8khc-_lK77g=s0",
    contract: "0xC43a7d62db13573315492F466f33b61ecEE75081",
    openSea: "https://opensea.io/collection/whowhos-treehouse"
    },
    {
      title: "DeSante Records Collection",
      image: "https://lh3.googleusercontent.com/WKVIjzDPv0nPL2ZACKLE0oUrdHI8VwZsTXFICwApnk4y14iPLbt1QERdW9wOmPOybHr0DRkwkPmVpd1UWFKwWHR8oS_E30PNpl2E=s0",
      contract: "0x24571c6DB7919a4AfB8A2953a4f5287d16fE496E",
      openSea: "https://opensea.io/collection/desante-records",
      selection: "Genesis",
      sortTrait: "Artist"
    },  
    {
    title: "I'm Spottie",
    image:
      "https://lh3.googleusercontent.com/02d5Yyq2ySSMxb5wkWir7BqfLjr5DWPUOEKOrwsFQTWMcyLSEu63SH1UVNiO5Gb4gnbharDRjTmmGyHzavBc_15mwxz9GcqN65swdg=s130",
    contract: "0xA0E1B198bCC877a950A29512ab5C0CE1Bb964c97",
    openSea: "https://opensea.io/collection/spottiewifi",

  },
  {
    title: "Richard Vagner The Hunt",
    image:
      "https://img.rarible.com/prod/image/upload/t_avatar_big/prod-collections/0xb16b333f2988fc808d585c5dff86911c0c38d0ec/avatar/QmanmuHVcRh12mjCqVTzGTG3Mr64TznPvE6xb9aiprKqzW",
    contract: "0xB16B333f2988fc808d585c5DFF86911c0C38d0EC",
    openSea: "https://rarible.com/richardvagnerhunt/items",
    sortTrait: "token_id"
  },
  {
    title: "EulerBeats Genesis",
    image:
      "https://lh3.googleusercontent.com/r-O1VIujQxmtqT9jKeun3jXwvuHyWjNJopRiYYWkHzSHQoiVPTPi1vkZqhhY5WGPe35U6HDM7gf6XZ20myxJ8MMvuNW5L0D8bqv_=s0",
    contract: "0x8754F54074400CE745a7CEddC928FB1b7E985eD6",
    openSea: "https://opensea.io/collection/eulerbeats",

  },
  {
    title: "EulerBeats Enigma",
    image:
      "https://lh3.googleusercontent.com/wGLWEIFEO5GANc93z7yJMajxLnvvkfSsVg3M0evrd-eCnmiK2dyQnBIXaiTn0ImATw4-2WKyTWm79g9F4MUO9eewF4WiXeMJXTnL=s130",
    contract: "0xa98771a46Dcb34B34cDAD5355718F8a97C8E603e",
    openSea: "https://opensea.io/collection/eulerbeats-enigma",

  },
  {
    title: "Snoop Dogg Ape Tracks",
    image:
      "https://lh3.googleusercontent.com/NrGZgKq54l0818ltZ9rA1ovbJWIz3q_VqJ23mOD2x5dsde5qyG5ZaJM5v7ysJyJKNDS_TL3Lfxr4t9EdZDJNVWtcoxaPFvMxhNhO=s0",
    contract: "0x647037de761696d224333778e69082ce3b742242",
    openSea: "https://opensea.io/collection/snoopdoggapetracks",

  },
  {
    title: "Snoop Dogg Sound.xyz",
    image:
      "https://www.sound.xyz/_next/image?url=https%3A%2F%2Fd2i9ybouka0ieh.cloudfront.net%2Fartist-uploads%2F6b735677-0696-4074-a7d1-d4c031bb1e27%2FRELEASE_COVER_IMAGE%2F8885092-IMG_1910.jpeg&w=3840&q=75",
    contract: "0x37A03D4AF1D7046d1126987b20117A0FdCBF6535",
    openSea: "https://www.sound.xyz/snoopdogg/death-row-mix-vol-1",
    selection: "#100",
    traitSelection: "name",
    //sortTrait: "token_id"

  },

  {
    title: "Ape In Productions",
    image:
      "https://lh3.googleusercontent.com/i0Nc2kudBFdj24YWl-8DgswmJSy-Wu4IqgHTw4zJCBa6Fr8nBbo514zc7nGZF6U97syhWQ1kQ8_OPPO4uO1vnkTmFCq70v-Hrw8jVA=s0",
    contract: "0xed493F841b0527FF4AD0C08ac9CF84fe26EBB5e1",
    openSea: "https://opensea.io/collection/ape-in-productions/",

  },
  {
    title: "Bored Brothers on Sound xyz",
    image:
      "https://www.sound.xyz/_next/image?url=https%3A%2F%2Fd2i9ybouka0ieh.cloudfront.net%2Fartist-uploads%2Fafe1e849-6be0-4203-b41f-e52bceb97e0d%2FAVATAR_IMAGE%2F5092219-Bored_Bro.jpeg&w=3840&q=75",
    contract: "0x1C95266be78a8bBE560B62bCa36c36f7c7C078d5",
    openSea: "https://opensea.io/collection/bored-brothers",
    selection: "200",
    traitSelection: "name"
  },
  {
    title: "We Are Kloud",
    image:
      "https://lh3.googleusercontent.com/ICJQZ_ahWfDPdtF-wz9gx79qTjU5A08L9fGkdB_HEyLT0EzYpE6zIdjZGPq4mwEDd4cT3PufBeMDohfppoX2RXdbfDxSxEYio7gh=s130",
    contract: "0xB8Da418FFC2Cb675B8B3d73dca0E3f10811FBbdD",
    openSea: "https://opensea.io/collection/we-are-kloud",

  },
  {
    title: "WVRPS by WarpSound",
    image:
      "https://lh3.googleusercontent.com/TLyqit6C4onBqWvjnxwApA0pcVTEKQymvkT5L-609EON9C09Y66-GAqY-N_o_g5OCUTQhR1hPPppnFYktblJEmwsQQjuKZoZ66iYpw=s130",
    contract: "0xcBC67Ea382F8a006d46EEEb7255876BeB7d7f14d",
    openSea: "https://opensea.io/collection/wvrps-by-warpsound",

  },
  {
    title: "Mint Songs Polygon",
    image:
      "https://res.cloudinary.com/crunchbase-production/image/upload/c_lpad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/ljj2pc7yd9hhrwpmh7hj",
    contract: "0xc29cBe04ae322469dc077741AFA2fbccDa748Ae4 CHAIN: POLYGON",
    openSea: "https://www.mintsongs.com/",
    selection: " 1/",
    traitSelection: "name"

  },
  {
    title: "Catalog Works",
    image:
      "https://www.notion.so/image/https%3A%2F%2Fs3-us-west-2.amazonaws.com%2Fsecure.notion-static.com%2F5a522dda-2ff2-4da1-a855-7bd6b127b186%2Fpfp.png?table=block&id=98ee8509-2bad-441d-aa1e-e9426daa4be8&spaceId=501cdc87-1d42-4fb3-b69a-4b8e00cfc5df&width=250&userId=&cache=v2",
    contract: "0x0bC2A24ce568DAd89691116d5B34DEB6C203F342",
    openSea: "https://beta.catalog.works/",
  },
  {
    title: "NOIZD",
    image:
      "https://www.notion.so/image/https%3A%2F%2Fs3-us-west-2.amazonaws.com%2Fsecure.notion-static.com%2F5a522dda-2ff2-4da1-a855-7bd6b127b186%2Fpfp.png?table=block&id=98ee8509-2bad-441d-aa1e-e9426daa4be8&spaceId=501cdc87-1d42-4fb3-b69a-4b8e00cfc5df&width=250&userId=&cache=v2",
    contract: "0xF5819E27B9bAD9F97c177Bf007c1F96F26D91CA6",
    openSea: "",
  },

  {
    title: "Song A Day",
    image:
      "https://lh3.googleusercontent.com/iXC6YJZDSNOLRp6rDqTJxhF1ehND3baGVnZmMTsQIruRR9jJ-VF5znqFjUUwfnYMEPQQrYH4pt7cPW4nMANI-6HBEl_a1dGgjeUXAg=s130",
    contract: "0x19b703f65aA7E1E775BD06c2aa0D0d08c80f1C45",
    openSea: "https://opensea.io/collection/song-a-day",

  },
  {
    title: "Metaversal",
    image:
      "https://gateway.pinata.cloud/ipfs/QmT3ujAiNSN31VWLoBpdTopFd6RzW7FVL4m1NX1j8Ma7Js",
    contract: "0x7E5CF7D20eF9A01013120e6C70C56924968C4f61",
    openSea: "https://opensea.io/collection/metaversal-the-programmatic-blockchain-album",

  },
  {
    title: "The Orbs by BT",
    image:
      "https://lh3.googleusercontent.com/CpUPdCh-yk-F8w68DjV5Dt2hLtLFq7gFtZNGITrBlMwdul2ZR_qxGcz18gt-502WgDADvOnHYoDf9yFdR-FfuVDLpu9N9iRMd2es=s130",
    contract: "0x52E66cA968010d064938A8099a172CBAaf08c125",
    openSea: "https://opensea.io/collection/theorbsbybt",

  },
  {
    title: "Art Blocks",
    image:
      "https://etherscan.io/token/images/artblocksnew_32.png",
    contract: "0xa7d8d9ef8D8Ce8992Df33D8b8CF4Aebabd5bD270",
    openSea: "https://opensea.io/collection/algorhythms-by-han-x-nicolas-daniel",

  },







];
