import React from 'react';
import { useState, useEffect, useRef } from "react";
import { Link, useParams } from "react-router-dom";

import "./Create.css";
import { Tabs, Card } from "antd";
import { useAlbum } from "../hooks/useAlbum";
import { useContractFilter } from "../hooks/useContractFilter";
import { useTokensWOMetadata } from "../hooks/useTokensWOMetadata";

import { PlusOutlined, QuestionOutlined, LoadingOutlined } from "@ant-design/icons";


import { LoginOutlined } from "@ant-design/icons";

import { useMoralis, useMoralisWeb3Api, useWeb3ExecuteFunction } from "react-moralis"

import { abi } from "../helpers/ERC721Jabi";
import { wizardAbi } from "../helpers/contractWizardAbi";



const Opensea = "https://arweave.net/t_sIwpq8gHura4FI3D2dz7AIwG97KL7inExQXbPWIDg";
const ContractWizard1 = "https://arweave.net/scMf9iGEEf-q5WuuzZvIJn1yKI8fnNiHjKSs3zNvs_8"
const ContractWizard2 = "https://arweave.net/NQKeo_yfOY2TYk7vNgiyZX6EmvrMO0ikjHVXqXdcBH4"
const BenApeTape = "https://arweave.net/fywJXh2FHI6rusySW5bSc0EcQNT78m0JvBKluY3Nyxg";
const ApeTapesText = "https://arweave.net/0RH8jPbjagMdPOgtJkqnRTUmegHBRJNMhIJkQ2ZsEYs";
const BoltBoy = "https://arweave.net/U0L4WMzuYw8KrezHeY9wtbBM5YY5CPejjjUxLDgvvRs";


const { TabPane } = Tabs;




const Create = () => {

  const { Moralis, user, authenticate, isAuthenticated, isAuthenticating, logout } = useMoralis();
  const { native } = useMoralisWeb3Api();
  const Web3Api = useMoralisWeb3Api();

  const functionProcessor = useWeb3ExecuteFunction();

  // if (isAuthenticated ) {
  //   Moralis.enableWeb3()
  // }

  const { tokensWOMetadata } = useTokensWOMetadata();


  useEffect(async () => {

    if (isAuthenticated) {
      var serverUrl = "https://tlfacj3dnlla.usemoralis.com:2053/server"
      var appId = "w1cag2MIVxTsOh4GxoqbMnSDAHupEbTIN7w8SKqv"
      var masterKey = "vT022tXIfaL21vEkqa2bVpvOOdQZ5kWu13hzsIKI"
      await Moralis.start({ serverUrl, appId, masterKey });
      // Moralis.enableWeb3()
    }

  }, []);



  var address
  //mainnet test contracts
  // var library = [
  //   "0x24571c6DB7919a4AfB8A2953a4f5287d16fE496E"
  // ]
  //rinkeby test contracts
  // var library = ["0xBbE1E9c58405f95f6945f63A94bc049dbb3Cff8B", "0x452e2Fb6338c14Aa78b9b708bFB399E4b8Bd8eE6", "0x245B5913b4B319e922CA4a4e576ffDcc769ceb3e"];

  var library = ["0x3435253A998d8078D2d4fbf9300539DC8596aD13", "0x13183C8031AC25515953288702a76C3959bb2bAf", "0xfc76830E896b8fD84848C7CA7470113dd9dfe038"];


  var paramTokenId



  async function contractCount() {


    const options = {
      // chain: "rinkeby",
      chain: "eth",
      address: "0x4539EFD776DA1832fDb0CabB7FdABa182FaC27DE",
      function_name: "contractCount",
      abi: wizardAbi,
      params: {},
    };

    const results = await Moralis.Web3API.native.runContractFunction(options);
    // console.log(results, "results")
    return results
    // return await Moralis.Web3API.native.runContractFunction(options);
  }


  async function mintCopyTo(token_address, token_id, to_address, val) {

    let options = {
      // chain: "rinkeby",
      chain: "eth",
      contractAddress: token_address,
      functionName: "mintCopyTo",
      abi: abi,
      params: { tokenId: token_id, to: to_address },
      msgValue: Moralis.Units.ETH(val)
    }

    // await functionProcessor.fetch({
    //   params: options
    // })
    await Moralis.executeFunction(options);


  }

  async function checkFreeClaim() {

    console.log("check free claim")
  }



  // async function watchTokenPriceSet() {


  //   var serverUrl = "https://tlfacj3dnlla.usemoralis.com:2053/server";
  //   var appId = "w1cag2MIVxTsOh4GxoqbMnSDAHupEbTIN7w8SKqv";
  //   var masterKey = "vT022tXIfaL21vEkqa2bVpvOOdQZ5kWu13hzsIKI";
  //   await Moralis.start({ serverUrl: "https://tlfacj3dnlla.usemoralis.com:2053/server", appId: "w1cag2MIVxTsOh4GxoqbMnSDAHupEbTIN7w8SKqv", masterKey: "vT022tXIfaL21vEkqa2bVpvOOdQZ5kWu13hzsIKI" });

  //   // for (let i = 0; i < library.length; i++) {

  //     let options = {
  //       chain: "rinkeby",
  //       // chain: "eth",
  //       address: library[0],
  //       // address: "0x3435253A998d8078D2d4fbf9300539DC8596aD13",
  //       topic: "TokenPriceSet(address, uint256)",
  //       abi: abi,
  //       limit: 500000,
  //       tableName: "TokenPriceSet" + library[0],
  //       sync_historical: true,
  //     };

  //     await Moralis.Cloud.run("watchContractEvent", options, { useMasterKey: true });

  //   // }

  //   console.log("set to watch token price set event")
  // }


  async function watchFreeClaim() {

    // var cAddy =  "0x3435253A998d8078D2d4fbf9300539DC8596aD13"
    // code example of creating a sync event from cloud code

    for (let i = 0; i < library.length; i++) {

      let options = {
        // chain: "rinkeby",
        chain: "eth",
        // address: library[i],
        address: "0x3435253A998d8078D2d4fbf9300539DC8596aD13",
        topic: "SetFreeClaim(address, uint256, uint256)",
        abi: abi,
        limit: 500000,
        tableName: "SetFreeClaim" + library[i],
        sync_historical: true,
      };

      await Moralis.Cloud.run("watchContractEvent", options, { useMasterKey: true });

    }

    console.log("set to watch Set Free Claim event")
  }




  if (user) {
    address = user.get("ethAddress").toUpperCase();

  }

  //gets list of nfts from wallet address
  console.log(address, "address is this")
  const { albumDetails } = useAlbum(address, 3);
  console.log(albumDetails, "this is in the create tab")


  const contractSet = [...new Set(albumDetails && albumDetails.map(data => data.token_address))]
  // console.log(contractSet, "full list of unique different contracts from list")


  const { filterContracts } = useContractFilter(albumDetails, address,
    library);
  console.log(filterContracts, "this is it");



  const copyText = useRef([]);


  console.log(abi, "ABI")


  let { creation } = useParams();
console.log(creation, "create creation from params")
var ass = "1"
if (creation == "contract"){ass="2"}
if (creation == "copy"){ass="1"}


  if (!isAuthenticated) {

    return (

      <Tabs defaultActiveKey={ass}  centered>
        <TabPane tab="Your Copies" key="1">

          <>
            <div className="albums">
              <button onClick={() => authenticate({ signingMessage: "Log in to ApeTapes" })} className="logIn" >
                <LoginOutlined /> Log In
              </button>


              <div
                className="openSeaButtonCreate"
                onClick={() =>
                  window.open(
                    "https://opensea.io/collection/desante-records"
                  )
                }
              >
                Get some tokens on OpenSea
                <img src={Opensea} alt="OpenSeaLogo" className="openLogo" />

              </div>

            </div>

            <div className="container" >
            </div>
          </>
        </TabPane>
        <TabPane tab="Contract Wizard" key="2">
          <img src={ContractWizard2} style={{ width: "80%", marginLeft: "10%" }} />
          <h1 className="featuredTitle">Contract Wizard!!</h1>
          <div className="albums">
            <container>
              <heading style={{ color: "white" }}>Coming Soon </heading>


            </container>
            <img src={BenApeTape} alt="Logo" className="logo2" style={{}}></img>

          </div>
        </TabPane>

      </Tabs>



    )
  }


  const reSyncMetadata = async (contract, id) => {

    const options = {
      // chain: "rinkeby",
      chain: "eth",
      address: contract.toString(),
      token_id: id.toString(),
      flag: "metadata",
    };
    const result = await Web3Api.token.reSyncMetadata(options);
    console.log(result, "resync metadata", contract, id);
    return result

  };


  return (




    <Tabs defaultActiveKey={ass} centered>
      <TabPane tab="Your Copies" key="1">

        {!filterContracts && <div className="loadingIcon">
          <LoadingOutlined />
        </div>
        }

        <div style={{ display: "flex", flexWrap: "wrap", color: "white", width: "90%", wordWrap: "break-word", gap: "10px", rowGap: "15px", paddingBottom: "20px" }}>
          {tokensWOMetadata && tokensWOMetadata.map(token => {
            console.log(token, "token");
            return (
              <div style={{ display: "flex", flexWrap: "wrap", color: "white", width: "90%", wordWrap: "break-word", }}>

                <div style={{ width: "180px" }}>Contract: {token.token_address.slice(2, 6) + "..." + token.token_address.slice(-4)} </div>
                {/* <div style ={{width:"50%"}}>Contract: {token.token_address} </div> */}
                <div style={{ width: "100px" }}> Token ID: {token.token_id}</div>

                <button style={{ color: "black" }} onClick={() => { reSyncMetadata(token.token_address, token.token_id) }}>
                  Resync metadata
                </button>
              </div>
            )
          })}
        </div>


        {filterContracts && filterContracts.length != 0 &&
          <p className='text'>Address to send copy:
            <input
              id="copyInput"

              type="text"
              placeholder="Address to"
              // value={copyText.current}
              style={{ width: "min(35%,300px)", marginBottom: "5%", marginTop: "5%", color: "black" }}
              onChange={e => { console.log(e.target.value); return copyText.current = e.target.value }}
              onKeyPress={(ev) => {
                if (ev.key === "Enter") {
                  ev.preventDefault();
                  console.log(ev.target.value);

                }
              }} />
          </p>}



        {filterContracts && filterContracts.length == 0 &&

          <div className="copyless">
            <p className="copyless">You don't seem to have any tokens that support minting copies. </p>
            <p className="copyless"> You need some tokens!</p>
            <div
              className="openSeaButtonCreate"
              onClick={() =>
                window.open(
                  "https://opensea.io/collection/desante-records"
                )
              }
            >
              Get some tokens on OpenSea
              <img src={Opensea} alt="OpenSeaLogo" className="openLogo" />

            </div></div>
        }

        <button className="copyButton" onClick={() => {
          checkFreeClaim()
        }}>
          Check Free Claims
        </button>
        {/* <button className="copyButton" onClick={() => {
          watchTokenPriceSet()
        }}>
          watchTokenPriceSet
        </button> */}
        <button className="copyButton" onClick={() => {
          watchFreeClaim()
        }}>
          watchFreeClaim
        </button>


        <div className="copies">



          {filterContracts && filterContracts.map((e, index) => {

            // console.log(JSON.parse(e.metadata), "e metadata")
            var imageCode
            if (JSON.parse(e.metadata).image) {
              imageCode = JSON.parse(e.metadata).image
            }
            if (JSON.parse(e.metadata).image_small) {
              imageCode = JSON.parse(e.metadata).image_small
            }


            var copyAddressBar



            return (




              <div className="copyDiv">
                <div style={{ width: "250%", overflow: "auto" }}>
                  <img
                    // src={JSON.parse(e.metadata).image_small}
                    src={imageCode}
                    // resolveLink()
                    className="copyImg"
                    alt="bull"
                    style={{ marginBottom: "10px", float: "left" }}
                  ></img><div className="copyTextDiv" >
                    <p style={{ marginBottom: "5px" }}>{e.name}</p>
                    <p style={{ marginBottom: "5px" }}>{e.token_address.slice(2, 6) + "..." + e.token_address.slice(-4)}</p>
                    <p style={{ marginBottom: "-10px" }}>{e.token_id}</p>
                    <p  ></p>

                    <button className="copyButton" onClick={() => {
                      mintCopyTo(e.token_address, e.token_id, copyText.current, 0.05)
                    }}>
                      Mint Copy To {copyText.current.slice(2, 6) + "..." + copyText.current.slice(-4)}
                    </button>
                  </div>

                </div>

              </div>
            )
          })}
        </div>





      </TabPane>
      <TabPane tab="Contract Wizard" key="2">
        <img src={ContractWizard2} className="wizard" />
        <h1 className="featuredTitle">Contract Wizard!!</h1>
        <div className="albums">
          <container>
            <heading style={{ color: "white" }}>Coming Soon </heading>


          </container>
          <img src={BenApeTape} alt="Logo" className="logo2" style={{}}></img>

        </div>
      </TabPane>

    </Tabs>
  )






}






export default Create;