import { useEffect, useState } from "react"
import { useMoralis } from "react-moralis"

import * as localForage from 'localforage';
import { abi } from "../helpers/ERC721Jabi";


export const useWizardContracts = (wizardContracts) => {


    const [wizardURIs, setWizardURIs] = useState();
    const { Moralis } = useMoralis();

    const d = new Date();
    let time = d.getTime();


    async function contractURI(input) {


        const options = {
            // chain: "eth",
            chain: "rinkeby",
            address: input,
            function_name: "contractURI",
            abi: abi,
            params: {},
        };

        const results = await Moralis.Web3API.native.runContractFunction(options);
        // console.log(results, "results")
        return results
        // return await Moralis.Web3API.native.runContractFunction(options);
    }

    const getContractURIs = async () => {

        const value = await localForage.getItem("wizardContractURIs");
        const valueTime = await localForage.getItem("wizardContractURIs" + "Time");

        if (time - valueTime < 2419200000) {
            if (value && value.length > 0) {
                console.log("wizard contractURIs got from localforage in useWizardContracts", value)
                setWizardURIs(value)
                return value
            }
        }

        var loopdata = [];


        const Results = Moralis.Object.extend("wizardContractURIs");
        const query = new Moralis.Query(Results);
        const queryResults = await query.find();

        if (queryResults.length == 1) {
            var contractURIs = queryResults[0].attributes.contractURIs
            console.log(queryResults[0].attributes, "queryResults")
            // console.log(queryResults[0].attributes.contracts, "queryResults")
            if (contractURIs && contractURIs.length > 0) {
                localForage.setItem("wizardContractURIs", contractURIs).then((value) => {
                    console.log("localForage set in useWizardContracts", value);
                })
                localForage.setItem("wizardContractURIs" + "Time", time).then((value) => {
                    console.log("localForage set in useWizardContracts", value);
                })
                setWizardURIs(contractURIs)
                return contractURIs;
            }
        }


        // const contracts = await query.get("contracts");
        for (let i = 0; i <= queryResults.length; i++) {
            console.log(queryResults, "queryResults")
            localForage.removeItem('wizardContractURIs')
            localForage.removeItem('wizardContractURIs' + "Time")
            if (queryResults && i < queryResults.length) {
                queryResults[i].destroy().then(
                    (myObject) => {
                        // The object was deleted from the Moralis Cloud.
                        console.log(myObject, "object was destroyed")
                    },
                    (error) => {
                        // The delete failed.
                    }
                )
            }
        }
        // console.log(contracts, "contracts")


        async function loop(count) {

            var results = "";
            if (wizardContracts && wizardContracts[count]) {
                results = await contractURI(wizardContracts[count]);
            }
            var url
            // url = await contractURIs(wizardContracts[count]);


            await fetch(results, {
                method: 'get'
            }).then(async function (response) {

                url = await response.json();
                console.log(url, "results here")
            }).catch(function (err) {
                // Error :(
            });



            setTimeout(async () => {

                // if (url && url.length > 0) {
                if (url) {
                    loopdata = loopdata.concat(url)
                    loopdata[count].contract = wizardContracts[count]
                    loopdata[count].platform = "Ape Tapes";
                    count++
                    // console.log(loopdata, count, "loopdata results")
                    console.log(count, "loopdata results")
                    if (count != wizardContracts.length) {
                        loop(count)
                    } else if (count == wizardContracts.length) {
                        console.log("the end was reached")


                        var wizard
                        wizard = new Results();
                        wizard.set("contractURIs", loopdata)
                        wizard.save().then(
                            (result) => {
                                console.log("saved wizard moralis object")
                            },
                            (error) => {

                            }
                        );


                        localForage.setItem("wizardContractURIs", loopdata).then((value) => {
                            console.log("localForage set in useWizardContracts", value);
                        })
                        localForage.setItem("wizardContractURIs" + "Time", time).then((value) => {
                            console.log("localForage set in useWizardContracts", value);
                        })

                        setWizardURIs(loopdata)
                        return loopdata;
                    }
                } else {
                    setWizardURIs(loopdata)
                    return loopdata;
                }

                // console.log("the end was reached 2")
                // return loopdata

            }, 250);
            // console.log(results, "results2")
            // console.log(results.length, "results length")
        }

        return loop(0);

    }



    useEffect(() => {

        console.log("got to useeffect")
        getContractURIs();


    }, [wizardContracts]);

    return { getContractURIs, wizardURIs };
}
