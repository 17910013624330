import React from 'react';
import "./About.css";
import { Card, Collapse } from "antd";

// import Discord from "../images/discord flat.png";
// import EtherscanLogoLightCircle from "../images/etherscan-logo-light-circle.png";
// import OpenSea from "../images/opensea.png";

import { TwitterOutlined, GithubOutlined, MediumOutlined } from "@ant-design/icons";

const Discord = "https://arweave.net/9jjLkzyUCwzGV_m9iMEppUUHtfoyXuc-kL06S2dTPDM";
const EtherscanLogoLightCircle = "https://arweave.net/iFk2uF8bP7GvQ06N8SfPIgvz6v4dxsBEswCWO2Is9N8";
const Opensea = "https://arweave.net/t_sIwpq8gHura4FI3D2dz7AIwG97KL7inExQXbPWIDg";


const ApeTapesText = "https://arweave.net/0RH8jPbjagMdPOgtJkqnRTUmegHBRJNMhIJkQ2ZsEYs";
const KupehIcon = "https://arweave.net/jQPxz25EUHs4fh3NnY7TjygMuuEEhl1fPvJad5KicGg"
const YeahbuddyIcon = "https://arweave.net/Z3LOCCiKTLCDzhAxxNhNvkYf7VOvKIv-vZOl9usyrlM"
const PabloIcon = "https://arweave.net/Clh-zLEhbJl9ld9WaVv4yzFgUMHBGyRyny2uhNxPGaI"
const JasonIcon = "https://arweave.net/WVeHY0MvlVc8uVwrKt6OkM33NFkvPp9Xx2JMXR9XL1s"
const MutantPoker = "https://arweave.net/NILkzGMCK8hf-5bkUiuHwl-5W0q7WtdbiSMj8baEmDY"

const { Panel } = Collapse;

const gridStyle = {
  width: '33%',
  textAlign: 'center',
  padding: '5px',
  height: '60px',
};


const About = ({ setIsSearch, collapsed }) => {



  return (
    <>
      <h1 style={{ marginLeft: "15px" }}></h1><img src={ApeTapesText} alt="Logo" className="logoAbout"></img>
      <Collapse ghost accordion className="collapse" >
        <Panel header="Info" key="1">
          <Collapse defaultActiveKey="1" ghost accordion className="collapse2">
            <Panel header="About" key="1">
              <p className="collapse3"><ul>
                <li>Ape Tapes is a website about web3 music.</li>
                <li>Listen to music nfts from your wallet and the library.</li>
                <li>Search the blockchain.</li>
                <li>Mint copies to your friends, create smart contracts and more. </li>
              </ul></p>
            </Panel>
            <Panel header="Playing Music" key="2">
              <p className="collapse3"><ul><li>Shuffle a collection, or play an album all the way through, the choice is yours.</li>
                <li>This is using more than one type of decentralized file storage.</li>
                <li>Loading times may vary depending on the token.</li>
                <li>For Arweave, loading is usually near instant, but there are times it isn't because we are still early.</li>
              </ul></p>
            </Panel>
            <Panel header="Web3" key="3">
              <p className="collapse3"><ul>
                <li>It can be slow to load full collections because it can grab all 10,000+ tokens at once.</li>
                <li>This is a work in progress web3 music player.</li>
                <li>Minting a copy of a song is a feature started in the DeSante Records contract, and is part of the ERC721J token. The Contract Wizard is also based off of this token.</li>
              </ul></p>
            </Panel>
          </Collapse>
        </Panel>
        <Panel header="Search" key="2" >
          <Collapse defaultActiveKey="1" ghost accordion className="collapse2">
            <Panel header="Anything" key="1"  >
              <p className="collapse3"><ul>
                <li>Any wallet. Search any wallet address. ENS too.</li>
                <li>Any contract. Get all the songs from any contract by searching the address</li>
                <li>Any metadata. Search for what makes the token unique</li>
              </ul></p>
            </Panel>
            <Panel header="Multi Chain" key="2" >
              <p className="collapse3"><ul>
                <li>ETH, Polygon, BSC support</li>
                <li>ETH is the default chain.</li>
                <li>Enter “ chain: polygon” at the end of your search to enable searching on polygon. </li>
                <li>“ chain: bsc” at the end of your search will change the chain to bsc.</li>
              </ul></p>
            </Panel>
            <Panel header="Advanced Search" key="3"  >
              <p className="collapse3"><ul>
                <li>“ filter:  name” will search by name in metadata.</li>
                <li>“ filter: description” will search only the description in metadata</li>
                <li>“ filter: attributes” will search only the attributes in metadata</li>
                <li>These are ways to make search run faster.</li>
              </ul></p>
            </Panel>
          </Collapse>
        </Panel>
        <Panel header="Wallet" key="3">
          <p className="collapse3">
            <ul>
              <li>Sign in with your Metamask wallet.</li>
              <li>View your NFTs, mint copies, and create smart contracts while signed in.</li>
              <li>Your Wallet listens to your music NFTs</li>
              <li>Your Copies mints copies of songs</li>
            </ul>
          </p>
        </Panel>
        <Panel header="Library" key="4">
          <Collapse defaultActiveKey="1" ghost accordion className="collapse2">
            <Panel header="Featured" key="1">
              <p className="collapse3"><ul><li>Click anything in the featured tab to quickly bring up a list of songs.</li>
                <li>The featured list is a manually collected list of smart contracts.</li>
                <li>Featured includes entire music nft platforms, 10k collections and more.</li>
              </ul></p>
            </Panel>
            <Panel header="The Wizard's Library" key="2">
              <p className="collapse3"><ul><li>The Contract Wizard keeps track of all contracts that are created by it in The Wizard's Library.</li>
              </ul></p>
            </Panel>
          </Collapse>
        </Panel>
        <Panel header="Contract Wizard" key="5">
          <Collapse defaultActiveKey="1" ghost accordion className="collapse2" >
            <Panel header="Create A Contract Affordibly" key="1" >
              <p className="collapse3"><ul><li>More than 10 times less gas to deploy using the Wizard's powers</li>
                <li>Easy to use, just name it and click create.</li>
                <li>Less time coding tokens, and more time for you to be creative</li>
              </ul></p>
            </Panel>
            <Panel header="You Own It" key="2">
              <p className="collapse3"><ul><li>Create your own NFTs from a contract you own.</li>
                <li>100% of the sales, primary and secondary, are yours.</li>
                <li>Create as many NFTs as you want.</li>
              </ul></p>
            </Panel>
            <Panel header="Customizable" key="3">
              <p className="collapse3"><ul><li>Set any edition size per song. Any amount of rarities.</li>
                <li>Set a price in eth, or only ask for Ape Coin.</li>
                <li>The Contract Wizard guides you every step along the way from contract to nft.</li>
              </ul>  </p>
            </Panel>
            <Panel header="The Wizard's Library" key="4">
              <p className="collapse3"><ul><li>The Wizard's Library is an autonomous archive used to keep track of your favorite Web3 music, a list of all the contracts made. </li>
                <li>A list to populate all web3 music players with it's contracts is The Wizard's Legacy.</li>
                <li>The Contract Wizard uses an ERC721J token.  It's music/art choices separate it from a regular ERC721.</li></ul>  </p>
            </Panel>
          </Collapse>
        </Panel>
      </Collapse>
      <Collapse ghost accordion className="collapse2" >
        <Panel header="Credits" key="6" style={{ fontSize: "20px" }} >
          <div className="collapse3" >
            <ul>
              <div style={{ overflow: "auto" }}><img src={KupehIcon} style={{ width: "100px", float: "left" }} /><div style={{ paddingTop: "40px", paddingLeft: "110px", verticalAlign: "middle" }}><a href="https://twitter.com/kupeh_rod" target="_blank">Kupeh Rodriguez</a>: Art</div></div>
              <div style={{ overflow: "auto" }}><img src={YeahbuddyIcon} style={{ width: "100px", float: "left" }} /><div style={{ paddingTop: "40px", paddingLeft: "110px", verticalAlign: "middle" }}><a href="https://twitter.com/Yeahbuddy_80" target="_blank">Yeahbuddy_80</a>: Ape Tapes tag</div></div>
              <div style={{ overflow: "auto" }}><img src={PabloIcon} style={{ width: "100px", float: "left" }} /><div style={{ paddingTop: "40px", paddingLeft: "110px", verticalAlign: "middle" }}><a href="https://twitter.com/PabloLopez83" target="_blank">Pablo Lopez</a>: Dancing Ears animation</div></div>
              <div style={{ overflow: "auto" }}><img src={JasonIcon} style={{ width: "100px", float: "left" }} /><div style={{ paddingTop: "40px",paddingLeft: "110px", verticalAlign: "middle" }}><a href="https://twitter.com/jasondesante" target="_blank"> Jason</a>: Website, smart contracts and stuff</div></div>
            </ul>
          </div>
        </Panel>
      </Collapse>
      <Card bordered={false} style={{ backgroundColor: "transparent" }}>
        <a href="https://discord.gg/yUFFJpZ" target="_blank"><Card.Grid style={gridStyle} className="Discord"><img src={Discord} className="socials"></img> Discord</Card.Grid></a>
        <a href="https://twitter.com/jasondesante" target="_blank"><Card.Grid style={gridStyle} className="Twitter"><TwitterOutlined className="socials" /> Twitter</Card.Grid></a>
        <a href="https://medium.com/@jasondesante" target="_blank"><Card.Grid style={gridStyle} className="Medium"><MediumOutlined className="socials" /> Medium</Card.Grid></a>
        <a href="https://github.com/jasondesante/ERC-721J" target="_blank"><Card.Grid style={gridStyle} className="GitHub"><GithubOutlined className="socials" /> GitHub</Card.Grid></a>
        <a href="https://etherscan.io/address/0x24571c6DB7919a4AfB8A2953a4f5287d16fE496E" target="_blank"><Card.Grid style={gridStyle} className="Etherscan"><img src={EtherscanLogoLightCircle} className="socials"></img> Etherscan</Card.Grid></a>
        <a href="https://opensea.io/collection/desante-records" target="_blank"><Card.Grid style={gridStyle} className="OpenSea"><img src={Opensea} className="socials"></img> OpenSea</Card.Grid></a>
      </Card>

      <video controls loop autoPlay>
          <source src={MutantPoker} type="video/mp4" />
        </video>


    </>
  )
}

export default About;
