import React, { useState, useEffect } from 'react';
import "./Album.css";
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
// import Opensea from "../images/opensea.png";
// import SoundXYZ from "../images/soundxyzLogo.png";
import { ClockCircleOutlined, PlayCircleFilled, LoadingOutlined } from "@ant-design/icons";
import { useAlbum } from "../hooks/useAlbum";
import { useFilter } from "../hooks/useFilter";
import { useSorter } from "../hooks/useSorter";

import { useContractFilter } from "../hooks/useContractFilter";

import { useMoralis, useMoralisWeb3Api } from "react-moralis"

import { useIPFS } from "../hooks/useIPFS";

import * as localForage from 'localforage';

const Opensea = "https://arweave.net/t_sIwpq8gHura4FI3D2dz7AIwG97KL7inExQXbPWIDg";
const SoundXYZ ="https://arweave.net/7pfSVNt3amgwwzuZ32aTyKHwyWfh5fKILHAgSS9ESPU";

let selection = []


const Album = ({ setNftAlbum, setSongChoice, isSearch, searchText }) => {

  const ignoreCase = require('ignore-case');

  const { state: album } = useLocation();

  const { Moralis, user } = useMoralis();

  if (isSearch != true) { isSearch = false }
  console.log(isSearch, "isSearch")
  console.log(searchText, "search text inside album")


  var address;
  var type;

  var bypass = false;

  //cool stuff
  const [searchParams, setSearchParams] = useSearchParams();
  let { search } = useParams();
  // console.log(search, "album search from params")
  // console.log(album, "album from params")

  var contractParams = searchParams.get("search")
  // console.log(searchParams.get("search"), "album contract searchparams")
  //

  // useEffect( () => {
  // //   // setSearchParams({nuts:"deeznutsssss", wallet:walletAddress})
  //   if(album&&album.contract){
  //     setSearchParams({contract:album.contract})
  //   }

  // }, []);


  if (album) {
    //type 1 is contract
    if (!album.contract && typeof album === 'string' || album instanceof String) {
      address = album
    } else {
      address = album.contract
    }
    type = 1

  }
  else if (isSearch == true) {
    //type 2 is search 
    address = searchText
    // if (searchText && searchText.length==42){
    //   type = 1
    // }else{
      type = 2
    // }
  }
  else if (search) {
    address = search
    if (search && search.length == 42 &&  ignoreCase.equals(search.slice(0, 2),"0X")  ) {
      type = 1
    } else {
      type = 2
    }

  }
  else if (contractParams) {
    address = contractParams
    if (contractParams && contractParams.length == 42 &&  ignoreCase.equals(contractParams.slice(0, 2),"0X")    ){
        type = 1
      }else{
      type = 2
    }
    // if (contractParams.includes(".eth")){

    // }

  }
  else {
    //type 3 is wallet
    if (user) {
      address = user.get("ethAddress")
      console.log(user.get("ethAddress"), "wallet", user.attributes, "user attributes!!")
    }
    type = 3

  }

  if (address) {
    address = address.toUpperCase();
  }


  if (type == 2) {
    bypass = true;
  } else { bypass = false; }

  var selection
  var traitSelection
  var sorter

  if (album) {
    selection = album.selection2 ? [album.selection, album.selection2] :
      album.selection ? [album.selection] : []
    traitSelection = album.traitSelection ? [album.traitSelection] : []
    sorter = album.sortTrait ? [album.sortTrait] : []

    if (album.platform && album.platform == "Ape Tapes") {
      sorter = "Track Number";
    }

  } else {
    selection = []
    traitSelection = []
    sorter = []
  }


  const { albumDetails } = useAlbum(address, type, bypass);

  const { filterContracts } = useContractFilter(albumDetails, address);
  console.log(filterContracts, "after useContractFilter");


  const { playlistDetails } = useFilter(filterContracts,
    selection,
    traitSelection,
    address,
    type,
    bypass);




  const { sortedPlaylist } = useSorter(playlistDetails,
    sorter)



  const { resolveLink } = useIPFS();


  if (playlistDetails && album && album.sortTrait) {
    var pushToTop = playlistDetails.map(item => {
      let newItem2 = item
      let newItem = JSON.parse(item.metadata)
      if (album) {
        if (newItem.attributes[0]) {
          //console.log("yo whats up",newItem)
          newItem.attributes = newItem.attributes.sort((x, y) => (y.trait_type == album.sortTrait) - (x.trait_type == album.sortTrait))
          newItem2.metadata = JSON.stringify(newItem)
        }
      }
      return newItem2
    })
  }


  // if is here because it crashes when a search tries to make sets right now.  need to fix that bug.
  if (type != 2) {

    const traitTypeSet = [...new Set(playlistDetails && playlistDetails.map(data => { if (JSON.parse(data.metadata).attributes == "") { return } if (JSON.parse(data.metadata).attributes.artist) { return JSON.parse(data.metadata).attributes.artist } return JSON.parse(data.metadata).attributes.map(dataa => dataa.trait_type) }))]
    // const traitTypeSet = [...new Set(playlistDetails && playlistDetails.map(data => { if (JSON.parse(data.metadata).attributes == "") { return }  console.log(JSON.parse(data.metadata).attributes, "data metadata"); return JSON.parse(data.metadata).attributes }))]
    const allTraitTypes = [...new Set(traitTypeSet.flat())]
    console.log(allTraitTypes, "full list of traits in collection")

    if (pushToTop) {
      const traitValueSet = [...new Set(pushToTop && pushToTop.map(data => { var thing = JSON.parse(data.metadata); if (thing.attributes == "") { return } return thing.attributes[0].value }))]
      const allTraitValues = [...new Set(traitValueSet.flat())]
      console.log(allTraitValues, "full list of trait values for trait")
    }

  }


  const searchTrait = (traitType) => {

    if (sortedPlaylist && sortedPlaylist[0]) {
      let nftJson = JSON.parse(sortedPlaylist[0].metadata)
      for (let j = 0; j < (sortedPlaylist && nftJson.attributes.length) || j < 0; j++) {
        if (ignoreCase.includes(nftJson.attributes[j].trait_type, traitType)) {
          return nftJson.attributes[j].value
        }
      }

      if (traitType == "contract") {
        return sortedPlaylist[0].name
      }
      if (traitType == "symbol") {
        return sortedPlaylist[0].symbol
      }

    }
  }


  function getRandomInt(max) {
    return Math.floor(Math.random() * max);
  }

  const durationSet = [...new Set(sortedPlaylist && sortedPlaylist.map(data => JSON.parse(data.metadata).duration))]
  // console.log(durationSet, "full list of songs with durations")


  const contractSet = [...new Set(sortedPlaylist && sortedPlaylist.map(data => data.token_address))]
  // console.log(contractSet, "full list of unique different contracts from list")


  var durationText = "00:00"
  var numberText
  var img
  var title
  if (album && !imageClick || album && imageClick == 0) {
    img = album.image
    title = album.title
    if (!title) {
      title = album.name
    }
  } else {
    img = ""
    title = ""
  }

  if (type == 1) {
    var artistText = searchTrait("Artist");
    if (!artistText) { artistText = searchTrait("contract") }
    var yearText = searchTrait("Year");
    if (!yearText) { yearText = searchTrait("symbol") }
  }

  var [imageClick, setImageClick] = useState(0);

  var imageText;
  var imageCode = <img onClick={function () { setImageClick(imageClick + 1) }} src={img} alt="albumcover" className="albumCover"></img>;


  var vidSrc ='https://arweave.net/n4cH9Pswf6ZA4t-kKcGrJ__IwDt5TOXK3Xl6WepbrFM'



  if (img == "" || !img) {
    imageCode =
      <div className="albumCover">
        <video controls loop autoPlay>
          <source src={vidSrc} type="video/mp4" />
        </video>
        <button onClick={function () { img = "yo it changed"; imageCode = img; setImageClick(imageClick + 1); console.log("some bullshit", img, imageCode, imageClick); }} className="nextImage" ></button>
      </div>
  }

  if (imageClick > (sortedPlaylist && sortedPlaylist.length)) {
    if (imageClick > (sortedPlaylist && (sortedPlaylist.length + 1))) {
      setImageClick(0)
    }
    if (album && imageClick == (sortedPlaylist && sortedPlaylist.length + 1)) {
      imageCode = <div className="albumCover">
        <video controls loop autoPlay>
          <source src={vidSrc} type="video/mp4" />
        </video>
        <button onClick={function () { img = "yo it changed"; imageCode = img; setImageClick(imageClick + 1); console.log("some bullshit", img, imageCode, imageClick); }} className="nextImage" ></button>
      </div>
      imageText = ""
      // setImageClick (imageClick + 1)
    }
    if (!album) {
      setImageClick(0)
    }

  }


  if (imageClick > 0 && imageClick <= (sortedPlaylist && sortedPlaylist.length)) {
    if (sortedPlaylist && sortedPlaylist[imageClick - 1] && sortedPlaylist[imageClick - 1].metadata) {
      imageCode =
        //  <div className="text">
        <img onClick={function () { setImageClick(imageClick + 1); console.log("some bullshit 2", img, imageCode, imageClick); }}
          src={resolveLink(JSON.parse(sortedPlaylist[imageClick - 1].metadata).image)} alt="albumcover" className="albumCover">
        </img>
      imageText = <div className="text">
        {JSON.parse(sortedPlaylist[imageClick - 1].metadata).name}
      </div>
      // </div>
      console.log(imageCode, "imagecode")
    } else {
      img = "changed"
      imageCode = "changed"
    }
  }

  var albumLink
  if (album) {
    albumLink = album.openSea;
  }
  var externalLinkText = "OpenSea"
  var externalLogo = Opensea;

  if (album && album.platform) {
    if (album.platform == "Sound XYZ") {
      albumLink = album.external_link;
      externalLinkText = "Sound XYZ";
      externalLogo = SoundXYZ;
    }


  }


  var layer2Buttons = <div
    className="openButton"
    onClick={() =>
      window.open(
        albumLink
      )
    }
  >
    {externalLinkText}
    <img src={externalLogo} alt="OpenSeaLogo" className="openLogo" />
  </div>

  var playlistLengthText;
  if (sortedPlaylist && sortedPlaylist.length == 1) {
    playlistLengthText = sortedPlaylist.length + " Song"
  } else if (sortedPlaylist && sortedPlaylist.length > 1) {
    playlistLengthText = sortedPlaylist.length + " Songs"
  }

  var loadingIcon = <LoadingOutlined />
  if (albumDetails && albumDetails.length == 500 || albumDetails && albumDetails.length == 5000) {
    loadingIcon = <LoadingOutlined />
  } else if (albumDetails && albumDetails.length > 1) { loadingIcon = "" }


  const [listLength, setListLength] = useState(100);
  const [refreshServerDone, setRefreshServerDone] = useState();
  const [refreshLocalDone, setRefreshLocalDone] = useState();


  var listLengthCode;

  console.log(listLength, "listlength ")



  // if (){ 
  //   listLengthCode = "";
  // }

  const increaseListLength = () => {
    console.log("clicked the button")
    listLengthCode = "";
    return setListLength(1000000)
  }



  if (listLength == 100 && sortedPlaylist && sortedPlaylist.length > 100) {
    listLengthCode =
      <div >
        {listLength || "..."}{" on page."}
        <button className="buttonMoreTokens" onClick={() => increaseListLength()}>Give me all the tokens.</button>
      </div>
  }


  var refreshServerButtonCode;
  var refreshLocalButtonCode;

  const refreshServer = async () => {
    refreshServerButtonCode = "";
    destroyPlaylist();
    destroyPlaylist2();
    destroyStorage();
    return setRefreshServerDone(true);
  }
  const refreshLocal = async () => {
    refreshLocalButtonCode = "";
    destroyStorage();
    return setRefreshLocalDone(true);
  }

  const destroyPlaylist = async () => {

    const Results = Moralis.Object.extend("ResultData");
    const query = new Moralis.Query(Results);
    query.equalTo("contract", address.toUpperCase());
    const queryResults = await query.find();
    for (let i = 0; i <= queryResults.length; i++) {
      queryResults[i].destroy().then(
        (myObject) => {
          // The object was deleted from the Moralis Cloud.
          // alert("Successful deletion of result data");
        },
        (error) => {
          // The delete failed.
          // error is a Moralis.Error with an error code and message.
        }
      )
    }
    return;
  }

  const destroyPlaylist2 = async () => {

    const Results = Moralis.Object.extend("FilterData");
    const query = new Moralis.Query(Results);
    query.equalTo("contract", address.toUpperCase());
    if (selection && selection.length > 0) {
      query.containsAll("selected", selection);
    } else {
      query.doesNotExist("selected");
    }
    if (traitSelection && traitSelection.length > 0) {
      query.containsAll("traitSelected", traitSelection);
    } else {
      query.doesNotExist("traitSelected");
    }
    const queryResults = await query.find();
    for (let i = 0; i < queryResults.length; i++) {
      queryResults[i].destroy().then(
        (myObject) => {
          // The object was deleted from the Moralis Cloud.
          // alert("Successful deletion of filter data");
        },
        (error) => {
          // The delete failed.
          // error is a Moralis.Error with an error code and message.
        }
      )
    }

    return;
  }

  const destroyStorage = async () => {
    localForage.removeItem(address.toUpperCase())
    localForage.removeItem(address.toUpperCase() + "Time")
    localForage.removeItem(address + JSON.stringify(selection) + JSON.stringify(traitSelection))
    localForage.removeItem(address + JSON.stringify(selection) + JSON.stringify(traitSelection) + "Time")
  }

  var walletAddress;
  if (user) {
    walletAddress = user.get("ethAddress")
    console.log(walletAddress, "wallet")
  }
  if (refreshServerDone != true && walletAddress && (walletAddress.toUpperCase() == "0xc737639FE05d27Bc7FCD8e3372f85C3dd2b3d1C1".toUpperCase() || walletAddress.toUpperCase() == address.toUpperCase())) {
    // if (refreshServerDone != true) {
    refreshServerButtonCode = <button className="buttonRefreshTokens" onClick={() => refreshServer()}>Refresh Server</button>
  }
  if (refreshLocalDone != true) {
    refreshLocalButtonCode = <button className="buttonRefreshTokens" onClick={() => refreshLocal()}>Refresh Local</button>
  }


  var contractHeadText
  var contractText
  if (type == 3) {
    contractHeadText = <div className="contractHeader" >Contract </div>
  }
  if (type == 3) {
    contractText = <div className="contractText" >Contract </div>
  }

  var imageText1
  var imageText2
  if (imageText) {
    imageText1 = <p className="imageText1">{imageText}</p>
  }
  if (imageText) {
    imageText2 = <p className="imageText2">{imageText}</p>
  }



  return (

    // console.log(albumDetails, "albumDetails"),
    // console.log(playlistDetails, "playlistDetails"),
    console.log(sortedPlaylist, "sortedPlaylist"),

    <div className="albumContent">
      <div>
        {refreshServerButtonCode}{refreshLocalButtonCode}
      </div>
      <div className="topBan">
        {imageCode}
        {imageText1}
        <div className="albumDeets">
          {/* <div>ALBUM</div> */}
          <div className="title" onClick={function () { setImageClick(sortedPlaylist && sortedPlaylist.length + 1) }}>{title}</div>
          <div className="artist" onClick={function () { console.log("some bullshit"); setImageClick(0) }} >
            {artistText || "results"}
          </div>

          <div>
            {yearText || "..."} •{" "}

            {/* {albumDetails && albumDetails.length} Song Contract{" "}•{" "} */}
            {playlistLengthText} {loadingIcon}
          </div>
          {listLengthCode}
        </div>
      </div>
      {imageText2}
      <div className="topBanButt">
        <div className="playButton" onClick={() => { return setNftAlbum(sortedPlaylist), setSongChoice(0), setImageClick(1) }}>
          PLAY
        </div>
        <div className="playButton" onClick={() => { var randNum = getRandomInt(sortedPlaylist.length); return setNftAlbum(sortedPlaylist), setSongChoice(randNum), setImageClick(randNum + 1) }}>
          SHUFFLE
        </div>
        <div className="layer2Buttons">
          {album && layer2Buttons}
        </div>
      </div>
      <div className="topBanButt2">

        <p className="OSButtons">{album && layer2Buttons}</p>

      </div>

      <div className="tableHeader">
        <div className="numberHeader1">#</div>
        {contractHeadText}
        <div className="titleHeader" >TITLE</div>
        {durationSet[0] &&
          <div   >
            <ClockCircleOutlined style={{ fontSize: "20px" }} />
          </div>
        }
      </div>




      {sortedPlaylist &&
        sortedPlaylist.slice(0, listLength).map((nft, i) => {


          const searchTrait = (traitType) => {
            for (let j = 0; j < nftJson.attributes.length; j++) {

              if (ignoreCase.includes(nftJson.attributes[j].trait_type, traitType)) {
                return nftJson.attributes[j].value
              }
            }

            if (traitType == "name") {
              return nftJson.name
            }
          }


          //{console.log(JSON.parse(nft.metadata), "nft.metadata")}
          if (!sortedPlaylist[i].metadata) {
            return console.log("error getting metadata", i)
          }
          let nftJson = JSON.parse(nft.metadata);


          if (durationSet[0]) { if (nftJson.duration) { durationText = nftJson.duration } else { durationText = "00:00" } }



          var titleText = searchTrait("Song")
          // if (!titleText) { titleText = searchTrait("song_title") }
          if (!titleText) { titleText = searchTrait("music_lead") }
          if (!titleText) { titleText = searchTrait("Beat") }
          if (!titleText) { titleText = searchTrait("Track") }
          if (!titleText) { titleText = searchTrait("name") }
          if (titleText == nft.token_id) { titleText = searchTrait("name") }
          numberText = ""
          if (!numberText) { numberText = searchTrait("Number") }
          if (!numberText) { numberText = JSON.parse(nft.token_id) }
          if (titleText == numberText) { titleText = searchTrait("name") }
          if (numberText > 10000000) { numberText = numberText.toString(10) }
          if (numberText > 10000000) { numberText = "..." + numberText.slice(-4) }


          var buttonCode = <button className="buttonStyle" key={i} onClick={() => { return setNftAlbum(sortedPlaylist), setSongChoice(i), setImageClick(i + 1), console.log(i, "clicked") }} onMouseEnter={() => console.log("mouse enter")}
            onMouseLeave={() => console.log("mouse leave")}  >{numberText}</button>

          if (contractText && nft.name) {
            contractText = <div
              className="contractText"
              style={{ color: "rgb(205, 203, 203)" }}
            > {(nft.name)}</div>
          } else { contractText = "" }



          return (
            <div></div>,

            <div key={i} className="tableContent" >


              <div className="numberHeader">
                {buttonCode}
              </div>
              {contractText && contractText}
              <div
                className="titleHeader"
                style={{ color: "rgb(205, 203, 203)" }}
              >
                {titleText}
              </div>

              {durationSet[0] &&
                <div className="numberHeader2" >
                  {durationText}
                </div>
              }
            </div>
          );
        })}
    </div>


  )
}

export default Album;

