export const soundXYZAbi = 

[
    {
        inputs: [
            { internalType: 'uint256', name: '', type: 'uint256' }
        ],
        name: 'artistContracts',
        outputs: [
            { internalType: 'address', name: '', type: 'address' }
        ],
        stateMutability: 'view',
        type: 'function'
    }
]